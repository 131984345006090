import React, { useEffect, useState } from 'react';
import { Row, Col, Modal, Button } from 'antd';
import { Document, Page, pdfjs } from 'react-pdf';
import { TERMS_AND_CONDITIONS, LANGUAGE, EMPTY_STRING, LANG_CLASS_NAME } from '../constants/appConstants';

import '../assets/css/TermsAndConditions.css';

const TermsAndConditions = ({
  rewardAndFinishLabelTranslation,
  language,
  setLanguage,
  setTermsAndConditionsVisible,
  termsAndConditionsVisible,
  setSyscoRewardsTermsAndConditionsVisible,
  syscoRewardsTermsAndConditionsVisible,
}) => {
  const [syscoTermsAndCondPdf, setSyscoTermsAndCondPdf] = useState(
    process.env.REACT_APP_SYSCO_TERMS_AND_CONDITIONS_URL_ENGLISH
  );

  const [syscoRewardsTermsAndCondPdf, setSyscoRewardsTermsAndCondPdf] = useState(
    process.env.REACT_APP_SYSCO_REWARDS_TERMS_AND_CONDITIONS_URL_ENGLISH
  );

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }
  useEffect(() => {
    setSyscoTermsAndCondPdf(
      language === LANGUAGE.FRENCH
        ? process.env.REACT_APP_SYSCO_TERMS_AND_CONDITIONS_URL_FRENCH
        : process.env.REACT_APP_SYSCO_TERMS_AND_CONDITIONS_URL_ENGLISH
    );
    setSyscoRewardsTermsAndCondPdf(
      language === LANGUAGE.FRENCH
        ? process.env.REACT_APP_SYSCO_REWARDS_TERMS_AND_CONDITIONS_URL_FRENCH
        : process.env.REACT_APP_SYSCO_REWARDS_TERMS_AND_CONDITIONS_URL_ENGLISH
    );
  }, [language]);

  const handleTermsAndConditionsClose = () => {
    setSyscoRewardsTermsAndConditionsVisible(false);
    setTermsAndConditionsVisible(false);
  };
  return (
    <Modal
      className="contactus-model"
      title=""
      centered
      visible={termsAndConditionsVisible}
      onOk={() => handleTermsAndConditionsClose()}
      onCancel={() => handleTermsAndConditionsClose()}
      width={650}
      footer={null}
      maskClosable={false}
      keyboard={false}
      zIndex={1000000}
      forceRender
      destroyOnClose
    >
      <Row className="model-body">
        <Col xs={16} sm={16} md={16} lg={18} xl={18} className="model-header">
          <p className="newcustomer-heading newcustomer-heading-terms">
            {syscoRewardsTermsAndConditionsVisible
              ? rewardAndFinishLabelTranslation?.rewards_sysco_terms_and_cond_header
              : rewardAndFinishLabelTranslation?.rewards_terms_and_cond_header}
          </p>
        </Col>
        <Col xs={8} sm={8} md={8} lg={6} xl={6} className="model-header">
          <span className="Language-selection-newcustomer-withoutstep">
            <a
              href="#!"
              className={language === LANGUAGE.ENGLISH ? LANG_CLASS_NAME : EMPTY_STRING}
              onClick={() => setLanguage(LANGUAGE.ENGLISH)}
            >
              EN
            </a>
            <span> I </span>
            <a
              href="#!"
              className={language === LANGUAGE.FRENCH ? LANG_CLASS_NAME : EMPTY_STRING}
              onClick={() => setLanguage(LANGUAGE.FRENCH)}
            >
              FR
            </a>
          </span>
        </Col>

        <Col span={14} className="col-centered-newcustomer-address ">
          <Document
            className="tandc-pdf"
            renderMode="svg"
            scale="0.8"
            file={syscoRewardsTermsAndConditionsVisible ? `${syscoRewardsTermsAndCondPdf}` : `${syscoTermsAndCondPdf}`}
            loading={
              syscoRewardsTermsAndConditionsVisible
                ? `${rewardAndFinishLabelTranslation?.rewards_loading_sysco_rewards_terms}`
                : `${rewardAndFinishLabelTranslation?.rewards_loading_sysco_terms}`
            }
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page pageNumber={pageNumber} />
          </Document>
          {!syscoRewardsTermsAndConditionsVisible && (
            <Document
              className="tandc-pdf"
              renderMode="svg"
              scale="0.8"
              file={
                syscoRewardsTermsAndConditionsVisible ? `${syscoRewardsTermsAndCondPdf}` : `${syscoTermsAndCondPdf}`
              }
              loading={EMPTY_STRING}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page pageNumber={pageNumber + 1} />
            </Document>
          )}
        </Col>

        <Col span="24" className="yourbusiness-footer">
          <span className="linkSeparator"></span>
          <Button className="catelog-btn" type="primary" onClick={() => handleTermsAndConditionsClose()}>
            OK
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default TermsAndConditions;
