import React, { useRef, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Modal, Button, Steps, Form, Select, Checkbox, Input } from 'antd';
import MaskedInput from 'antd-mask-input';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';

import _ from 'lodash';
import Error from '../components/error';
import InputField from '../components/InputField';

import {
  LANGUAGE,
  LANG_CLASS_NAME,
  EMPTY_STRING,
  INVALID_POSTALCODE_NAME,
  MAX_LENGTH_POSTAL_CODE,
  MAX_LENGTH_STREET_ADDRESS,
  PROVINCE_LIST_OPTION,
  PO_BOX,
  POSTAL_CODE_MASK,
  POSTAL_CODE_PLACEHOLDER,
  MAX_LENGTH_CITY,
} from '../constants/appConstants';
import { createShippingDetails, getOpcoLocation } from '../actions';

import '../assets/css/ShippingDetails.css';
import '../assets/css/NewCustomerAddress.css';

const LOADING_SHIPPING_LATENCY = process.env.REACT_APP_LOADING_SHIPPING_LATENCY;

const { Step } = Steps;

const ShippingDetails = ({
  shippingLabelTranslation,
  language,
  setLanguage,
  setShippingProvince,
  shippingProvince,
  shippingConfirm,
  shippingPostalCode,
  shippingCity,
  shippingAddress,
  customerUserId,
  isModalClosed,
  isSameBillingAddress,
  setIsPickUp,
  setIsdDelivery,
  setOnboardingShippingModalVisible,
  setBillingHeaderVisible,
  setRewardOptionActionNo,
  setOnboardingBillingModalVisible,
  setOnboardingBusinessModalVisible,
  setShippingTypeModalVisible,
  onboardingShippingModalVisible,
  setCloseConfirmationVisible,
  setShippingAddress,
  setShippingCity,
  setShippingPostalCode,
  setIsSameBillingAddress,
  setShippingConfirm,
  billingHeaderVisible,
  shippingResError,
  opcoResError,
  setShippingLoadingState,
  shippingLoadingState,
  setOpcoResError,
  shippingFetching,
  shippingIsSuccess,
  form,
  getFieldDecorator,
}) => {
  const dispatch = useDispatch();

  const CANADA_WEB_POST_API_TIME = process.env.REACT_APP_CANADA_WEB_POST_API_TIME;

  const [provinceErrorMsg, setProvinceErrorMsg] = useState(EMPTY_STRING);
  const [isProvinceError, setIsProvinceError] = useState(false);

  const [postalCodeErrorMsg, setPostalCodeErrorMsg] = useState(EMPTY_STRING);
  const [isPostalCodeError, setIsPostalCodeError] = useState(false);

  const [streetAddressErrorMsg, setStreetAddressErrorMsg] = useState(EMPTY_STRING);
  const [isStreetAddressError, setIsStreetAddressError] = useState(false);
  const [shippingResIsSuccess, setShippingResIsSuccess] = useState(false);

  const shippingResErrorRef = useRef(shippingResError);
  const opcoResErrorRef = useRef(opcoResError);

  const validateShippingFieldValue = (fieldName) => {
    form.validateFields([fieldName], (errors) => {
      if (errors) {
        form.resetFields([fieldName]);
      }
    });
  };

  const clearAllShippingFields = () => {
    validateShippingFieldValue('shippingStreetAddress');
    validateShippingFieldValue('shippingStreetCity');
    validateShippingFieldValue('shippingConfirmation');
    validateShippingFieldValue('shippingProvince');
    setStreetAddressErrorMsg(EMPTY_STRING);
    setIsStreetAddressError(false);
    setIsProvinceError(false);
    setProvinceErrorMsg(EMPTY_STRING);
    setIsPostalCodeError(false);
    setPostalCodeErrorMsg(EMPTY_STRING);
  };

  const loadBillingAddressModal = _.debounce((shippingResErrorRef, opcoResErrorRef) => {
    if (!shippingResErrorRef.current && !opcoResErrorRef.current) {
      setShippingLoadingState(false);
      setOnboardingShippingModalVisible(false);
      setBillingHeaderVisible(true);
      setOnboardingBillingModalVisible(true);
      setRewardOptionActionNo(5);
    }
  }, LOADING_SHIPPING_LATENCY);

  const loadShippingTypeAddressModal = _.debounce((shippingResErrorRef, opcoResErrorRef) => {
    if (!shippingResErrorRef.current && !opcoResErrorRef.current) {
      setShippingLoadingState(false);
      setOnboardingShippingModalVisible(false);
      setBillingHeaderVisible(false);
      setShippingTypeModalVisible(true);
      setRewardOptionActionNo(4);
    }
  }, LOADING_SHIPPING_LATENCY);

  const validateShippingAddress = (streetAddress) => {
    if (streetAddress?.trim().toLowerCase().startsWith(PO_BOX)) {
      setStreetAddressErrorMsg(shippingLabelTranslation?.error_msg_shipping_street_po_box);
      setIsStreetAddressError(true);
    } else {
      setStreetAddressErrorMsg(EMPTY_STRING);
      setIsStreetAddressError(false);
    }
  };

  const validateShippingPostalCode = (postalCode) => {
    if (postalCode?.trim() === EMPTY_STRING || postalCode?.trim().length < MAX_LENGTH_POSTAL_CODE) {
      setIsPostalCodeError(true);
      setPostalCodeErrorMsg(shippingLabelTranslation?.error_msg_shpiing_postal_code);
    } else {
      setIsPostalCodeError(false);
      setPostalCodeErrorMsg(EMPTY_STRING);
    }
  };

  const validateShippingProvince = (provinceName) => {
    if (
      provinceName !== undefined &&
      !_.isEmpty(provinceName?.trim()) &&
      !PROVINCE_LIST_OPTION.some((province) => province?.toLowerCase() === provinceName?.toLowerCase())
    ) {
      setProvinceErrorMsg(shippingLabelTranslation?.errro_message_shipping__province);
      setIsProvinceError(true);
    } else {
      setProvinceErrorMsg(EMPTY_STRING);
      setIsProvinceError(false);
    }
  };

  const handleShippingDetailsSubmit = (e) => {
    e.preventDefault();

    validateShippingAddress(shippingAddress);
    validateShippingPostalCode(shippingPostalCode);
    validateShippingProvince(shippingProvince);

    form.validateFields(
      ['shippingStreetAddress', 'shippingStreetCity', 'shippingConfirmation', 'shippingProvince'],
      (err) => {
        if (!err) {
          setOpcoResError(false);
          opcoResErrorRef.current = false;
          setShippingResIsSuccess(false);
          if (
            !isPostalCodeError &&
            !isProvinceError &&
            !isStreetAddressError &&
            document.getElementById('postcode')?.value?.trim() !== EMPTY_STRING &&
            document.getElementById('postcode')?.value?.trim().length === MAX_LENGTH_POSTAL_CODE &&
            PROVINCE_LIST_OPTION.filter((province) =>
              province.toLowerCase().includes(form.getFieldValue('shippingProvince')?.toLowerCase())
            ) &&
            shippingConfirm &&
            shippingPostalCode !== EMPTY_STRING &&
            shippingProvince !== EMPTY_STRING &&
            shippingCity !== EMPTY_STRING &&
            shippingAddress !== EMPTY_STRING
          ) {
            const shippingDetailsData = {
              userId: customerUserId,
              isModelClosed: isModalClosed,
              streetAddress: shippingAddress,
              city: shippingCity,
              province: shippingProvince?.trim(),
              postalCode: shippingPostalCode?.trim(),
              isSameBillingAddress,
              lang: language,
            };
            setShippingLoadingState(true);
            dispatch(createShippingDetails(shippingDetailsData));
            setIsPickUp(false);
            setIsdDelivery(false);
            if (!isSameBillingAddress) {
              loadBillingAddressModal(shippingResErrorRef, opcoResErrorRef);
            } else {
              loadShippingTypeAddressModal(shippingResErrorRef, opcoResErrorRef);
            }
          }
        }
      }
    );
  };

  const handleShippingDetailsBackButton = () => {
    setOnboardingBusinessModalVisible(true);
    setOnboardingShippingModalVisible(false);
  };

  useEffect(() => {
    shippingResErrorRef.current = shippingResError;
  }, [shippingResError]);

  useEffect(() => {
    if (opcoResError) {
      opcoResErrorRef.current = opcoResError;
      setShippingPostalCode(EMPTY_STRING);
      setShippingLoadingState(false);
    }
  }, [opcoResError]);

  useEffect(() => {
    if (shippingResIsSuccess) {
      dispatch(getOpcoLocation(language, customerUserId));
    }
  }, [shippingResIsSuccess]);

  const updateShippingAddress = _.debounce(() => {
    setShippingPostalCode(document.getElementById('postcode')?.value);
    setShippingAddress(document.getElementById('street-address')?.value);
    setShippingCity(document.getElementById('city')?.value);
    setShippingProvince(document.getElementById('province')?.value);
    form.setFieldsValue({
      shippingStreetAddress: document.getElementById('street-address')?.value,
    });
    form.setFieldsValue({
      shippingStreetCity: document.getElementById('city')?.value,
    });
    form.setFieldsValue({
      shippingProvince: document.getElementById('province')?.value,
    });
    validateShippingAddress(form.getFieldValue('shippingStreetAddress'));
    validateShippingPostalCode(document.getElementById('postcode')?.value);
    validateShippingProvince(form.getFieldValue('shippingProvince'));
  }, CANADA_WEB_POST_API_TIME);

  const handleShippingPostalCode = (e) => {
    e.preventDefault();
    if (e.target.value.trim().length < MAX_LENGTH_POSTAL_CODE) {
      setIsPostalCodeError(true);
      setPostalCodeErrorMsg(shippingLabelTranslation?.error_msg_shpiing_postal_code);
    } else {
      setIsPostalCodeError(false);
      setPostalCodeErrorMsg(EMPTY_STRING);
      setShippingPostalCode(e.target.value);
      updateShippingAddress();
    }
  };

  useEffect(() => {
    if (shippingIsSuccess && !shippingFetching) {
      setShippingResIsSuccess(shippingIsSuccess);
    }
  }, [shippingIsSuccess, shippingFetching]);

  return (
    <Modal
      className="contactus-model"
      title=""
      centered
      visible={onboardingShippingModalVisible}
      onOk={() => {
        setOnboardingShippingModalVisible(false);
        clearAllShippingFields();
      }}
      onCancel={() => {
        setCloseConfirmationVisible(true);
        clearAllShippingFields();
      }}
      width={1200}
      footer={null}
      maskClosable={false}
      keyboard={false}
      forceRender
    >
      <Row className="model-body">
        <Col xs={24} sm={24} md={8} lg={8} xl={6} className="model-header">
          <p className="newcustomer-heading">{shippingLabelTranslation?.steps_become_a_customer_default}</p>
        </Col>
        <Col xs={15} sm={15} md={12} lg={12} xl={14} className="model-header ncob-steps">
          <Steps size="small" current={2}>
            <Step title={shippingLabelTranslation?.steps_about_you} />
            <Step title={shippingLabelTranslation?.steps_your_business} />
            <Step title={shippingLabelTranslation?.steps_shipping_address} />
            {billingHeaderVisible ? <Step title={shippingLabelTranslation?.steps_your_billing_address} /> : null}
            <Step title={shippingLabelTranslation?.steps_shipping} />
            <Step title={shippingLabelTranslation?.steps_rewards_finish} />
          </Steps>
        </Col>
        <Col xs={9} sm={9} md={4} lg={4} xl={4} className="model-header">
          <span
            className={
              billingHeaderVisible
                ? 'Language-selection-newcustomer Language-selection-newcustomer-billing'
                : 'Language-selection-newcustomer Language-selection-newcustomer-without-billing'
            }
          >
            <a
              href="#!"
              className={language === LANGUAGE.ENGLISH ? LANG_CLASS_NAME : EMPTY_STRING}
              onClick={() => {
                setLanguage(LANGUAGE.ENGLISH);
                clearAllShippingFields();
              }}
            >
              EN
            </a>
            <span> I </span>
            <a
              href="#!"
              className={language === LANGUAGE.FRENCH ? LANG_CLASS_NAME : EMPTY_STRING}
              onClick={() => {
                setLanguage(LANGUAGE.FRENCH);
                clearAllShippingFields();
              }}
            >
              FR
            </a>
          </span>
        </Col>
        {shippingResError ? (
          <Error errorLabelTranslation={shippingLabelTranslation} />
        ) : (
          <Form
            target="blank"
            onSubmit={(e) => {
              e.preventDefault();
              handleShippingDetailsSubmit(e);
            }}
          >
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={14}
              xl={14}
              className="col-leftside-newcustomer-address shippingdetails-form-wrap"
            >
              {opcoResError ? <p className="error-msg">{shippingLabelTranslation?.address_error_message}</p> : null}
              <Col span={24} className="industry-text">
                <label className="catelog-lable">{shippingLabelTranslation?.shipping_postal_code}</label>
                <Form.Item>
                  <MaskedInput
                    id="postcode"
                    className={isPostalCodeError ? 'input-has-error' : EMPTY_STRING}
                    placeholder={shippingLabelTranslation?.shipping_postal_code}
                    value={shippingPostalCode}
                    onChange={(e) => handleShippingPostalCode(e)}
                    onBlur={() => {
                      updateShippingAddress();
                      setIsPostalCodeError(false);
                      setPostalCodeErrorMsg(EMPTY_STRING);
                    }}
                    maxLength={MAX_LENGTH_POSTAL_CODE}
                    title={INVALID_POSTALCODE_NAME}
                    autoComplete="off"
                    mask={POSTAL_CODE_MASK}
                    placeholderChar={POSTAL_CODE_PLACEHOLDER}
                  />
                </Form.Item>
                {isPostalCodeError ? <span className="error-msg">{postalCodeErrorMsg}</span> : EMPTY_STRING}
              </Col>
              <input id="street-address" hidden />
              <input id="city" hidden />
              <input id="province" hidden />
              <Col span={24} className="industry-text">
                <label className="catelog-lable">{shippingLabelTranslation?.shipping_street_address}</label>
                <Form.Item>
                  {getFieldDecorator('shippingStreetAddress', {
                    rules: [
                      {
                        required: true,
                        message: shippingLabelTranslation?.error_msg_shipping_street_address,
                      },
                    ],
                  })(
                    <Input
                      id="shippingStreetAddress"
                      className={isStreetAddressError ? 'input-has-error' : EMPTY_STRING}
                      placeholder={shippingLabelTranslation?.shipping_street_address}
                      value={shippingAddress}
                      onChange={(e) => {
                        setShippingAddress(e.target.value);
                        validateShippingAddress(e.target.value);
                      }}
                      maxLength={MAX_LENGTH_STREET_ADDRESS}
                    />
                  )}
                </Form.Item>
                {isStreetAddressError ? <span className="error-msg">{streetAddressErrorMsg}</span> : EMPTY_STRING}
              </Col>
              <Col span={24} className="industry-text">
                <InputField
                  isPatternRequried={false}
                  isSize={false}
                  getFieldDecorator={getFieldDecorator}
                  value={shippingCity}
                  setValue={setShippingCity}
                  maxLength={MAX_LENGTH_CITY}
                  isRequired
                  patternFormat={EMPTY_STRING}
                  labelName={shippingLabelTranslation?.shipping_city}
                  errorMsg={shippingLabelTranslation?.error_msg_shipping_city}
                  patternErrorMsg={shippingLabelTranslation?.error_msg_shipping_city}
                  idValue="shippingStreetCity"
                  isNotEmail
                />
              </Col>
              <Col span={24} className="industry-text province-select">
                <label className="catelog-lable">{shippingLabelTranslation?.shipping_province}</label>
                <Form.Item>
                  {getFieldDecorator('shippingProvince', {
                    rules: [
                      {
                        required: true,
                        message: shippingLabelTranslation?.errro_message_shipping__province,
                      },
                    ],
                  })(
                    <Select
                      className={`form-select form-select-shipping-address  ${isProvinceError ? 'has-error' : null}`}
                      placeholder={shippingLabelTranslation?.business_select_option}
                      value={shippingProvince}
                      onChange={(value) => {
                        setShippingProvince(value?.trim());
                        validateShippingProvince(value?.trim());
                      }}
                    >
                      {PROVINCE_LIST_OPTION?.map((option) => (
                        <Select.Option key={option} value={option}>
                          {option}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                  {isProvinceError ? (
                    <div className="has-error error-msg ant-form-explain">{provinceErrorMsg}</div>
                  ) : null}
                </Form.Item>
              </Col>
              <Col span={24} className="industry-text biiling-shipping-same">
                <Form.Item>
                  <Checkbox checked={isSameBillingAddress} onChange={(e) => setIsSameBillingAddress(e.target.checked)}>
                    {shippingLabelTranslation?.shipping_same_billing}
                  </Checkbox>
                </Form.Item>
              </Col>

              <Col span={24} className="industry-text">
                <hr className="menu-divider-rewards"></hr>
                <Form.Item>
                  {getFieldDecorator('shippingConfirmation', {
                    rules: [
                      {
                        required: true,
                        transform: (value) => value || undefined, // Those two lines
                        type: 'boolean',
                        message: shippingLabelTranslation?.error_msg_shipping_checkbox,
                      },
                    ],
                  })(
                    <Checkbox checked={shippingConfirm} onChange={(e) => setShippingConfirm(e.target.checked)}>
                      {shippingLabelTranslation?.shipping_confirm_msg}
                    </Checkbox>
                  )}
                </Form.Item>
              </Col>
            </Col>

            <Col span="24" className="yourbusiness-footer">
              <Button
                disabled={shippingLoadingState}
                className="form-btn-link btn-back"
                type="primary"
                onClick={() => handleShippingDetailsBackButton()}
              >
                {' '}
                <LeftOutlined />
                {shippingLabelTranslation?.shipping_back}
              </Button>
              <span className="linkSeparator"></span>
              <Button
                loading={shippingLoadingState}
                className="catelog-btn"
                type="submit"
                htmlType="submit"
                target="_blank"
              >
                {shippingLabelTranslation?.shipping_continue}
                <RightOutlined />
              </Button>
            </Col>
          </Form>
        )}
      </Row>
    </Modal>
  );
};

export default ShippingDetails;
