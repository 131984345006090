import { combineReducers } from 'redux';
import initialState from '../store/initialState';
import {
  CATALOGUES,
  CREATE_CATALOGUE_USER,
  CREATE_CONTACT_USER,
  CREATE_CUSTOMER,
  CREATE_CUSTOMER_BUSINESS,
  CREATE_SHIPPING_DETAILS,
  CREATE_BILLING_DETAILS,
  SEGMENTS,
  OPCO_LOCATION,
  CREATE_CUSTOMER_DELIVERY,
  CREATE_CUSTOMER_PICKUP,
  CREATE_CUSTOMER_REWARDS,
  LABEL_TRANSLATION,
} from '../actions/actionTypes';
import { createReducer } from '../reduxHelpers/index';

const catalogues = createReducer(CATALOGUES, initialState.catalogues);
const catalogueUser = createReducer(CREATE_CATALOGUE_USER, initialState.catalogueUser);
const contactUser = createReducer(CREATE_CONTACT_USER, initialState.contactUser);
const customer = createReducer(CREATE_CUSTOMER, initialState.customer);
const customerBusiness = createReducer(CREATE_CUSTOMER_BUSINESS, initialState.customerBusiness);
const shippingDetails = createReducer(CREATE_SHIPPING_DETAILS, initialState.shippingDetails);
const billingDetails = createReducer(CREATE_BILLING_DETAILS, initialState.shippingDetails);
const customerDelivery = createReducer(CREATE_CUSTOMER_DELIVERY, initialState.customerDelivery);
const customerPickUp = createReducer(CREATE_CUSTOMER_PICKUP, initialState.customerPickUp);
const customerRewards = createReducer(CREATE_CUSTOMER_REWARDS, initialState.customerRewards);

const opcoLocation = createReducer(OPCO_LOCATION, initialState.opcoLocation);
const segments = createReducer(SEGMENTS, initialState.segments);
const labelNames = createReducer(LABEL_TRANSLATION, initialState.labelNames);

export default combineReducers({
  segments,
  catalogues,
  catalogueUser,
  contactUser,
  customer,
  customerBusiness,
  shippingDetails,
  billingDetails,
  customerDelivery,
  customerPickUp,
  customerRewards,
  opcoLocation,
  labelNames,
});
