import { takeLatest, put, all, call } from 'redux-saga/effects';
import { getRequest, postRequest, putRequest } from '../_http';
import {
  CATALOGUES,
  CREATE_CATALOGUE_USER,
  CREATE_CONTACT_USER,
  CREATE_CUSTOMER,
  CREATE_CUSTOMER_BUSINESS,
  CREATE_SHIPPING_DETAILS,
  CREATE_BILLING_DETAILS,
  SEGMENTS,
  CREATE_CUSTOMER_PICKUP,
  CREATE_CUSTOMER_DELIVERY,
  CREATE_CUSTOMER_REWARDS,
  OPCO_LOCATION,
  LABEL_TRANSLATION,
} from '../actions/actionTypes';

import { action } from '../reduxHelpers';

function* loadCataloguesAsync({ language }) {
  try {
    const response = yield call(getRequest, `/catalogues?lang=${language}`);
    yield put({ type: CATALOGUES.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: CATALOGUES.FAILURE, payload: error.message });
  }
}

function* createCatalogueUserAsync({ catalogueId, language, data }) {
  try {
    const response = yield call(postRequest, `/catalogues/${catalogueId}?lang=${language}`, data);

    yield put({ type: CREATE_CATALOGUE_USER.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: CREATE_CATALOGUE_USER.FAILURE, payload: error.message });
  }
}

function* createContactUserAsync({ data }) {
  try {
    const response = yield call(postRequest, `/contact-details`, data);

    yield put({ type: CREATE_CONTACT_USER.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: CREATE_CONTACT_USER.FAILURE, payload: error.message });
  }
}

function* createCustomerBusinessAsync({ data }) {
  try {
    const response = yield call(putRequest, `/businesses`, data);

    yield put({ type: CREATE_CUSTOMER_BUSINESS.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: CREATE_CUSTOMER_BUSINESS.FAILURE, payload: error.message });
  }
}

function* createShippingDetailsAsync({ data }) {
  try {
    const response = yield call(putRequest, `/shipping-details`, data);

    yield put({ type: CREATE_SHIPPING_DETAILS.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: CREATE_SHIPPING_DETAILS.FAILURE, payload: error.message });
  }
}

function* createBillingDetailsAsync({ data }) {
  try {
    const response = yield call(putRequest, `/billing-details`, data);

    yield put({ type: CREATE_BILLING_DETAILS.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: CREATE_BILLING_DETAILS.FAILURE, payload: error.message });
  }
}

function* getSegmentsAsync({ language }) {
  try {
    const response = yield call(getRequest, `/segments?lang=${language}`);
    yield put({ type: SEGMENTS.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: SEGMENTS.FAILURE, payload: error.message });
  }
}

function* createCustomerAsync({ data }) {
  try {
    const response = yield call(putRequest, `/customers`, data);

    yield put({ type: CREATE_CUSTOMER.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: CREATE_CUSTOMER.FAILURE, payload: error.message });
  }
}

function* createCustomerRewardsAsync({ data }) {
  try {
    const response = yield call(postRequest, `/customers`, data);
    yield put({ type: CREATE_CUSTOMER_REWARDS.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: CREATE_CUSTOMER_REWARDS.FAILURE, payload: error.message });
  }
}

function* createCustomerDeliveryAsync({ data }) {
  try {
    const response = yield call(putRequest, `/deliveries`, data);

    yield put({ type: CREATE_CUSTOMER_DELIVERY.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: CREATE_CUSTOMER_DELIVERY.FAILURE, payload: error.message });
  }
}

function* createCustomerPickUpAsync({ data }) {
  try {
    const response = yield call(putRequest, `/pickup-details`, data);

    yield put({ type: CREATE_CUSTOMER_PICKUP.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: CREATE_CUSTOMER_PICKUP.FAILURE, payload: error.message });
  }
}

function* getOpcoLocationAsync({ language, userId }) {
  try {
    const response = yield call(getRequest, `/opco-locations?lang=${language}&userId=${userId}`);
    yield put({ type: OPCO_LOCATION.SUCCESS, payload: response.data });
  } catch (error) {
    if (error.response.status == 400) {
      yield put({ type: OPCO_LOCATION.FAILURE, error: error.response.data.description });
    } else {
      yield put({ type: OPCO_LOCATION.FAILURE, payload: error.message });
    }
  }
}

function* getLabelTranslationAsync({ language }) {
  try {
    const response = yield call(getRequest, `/label-translations?lang=${language}`);
    yield put({ type: LABEL_TRANSLATION.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: LABEL_TRANSLATION.FAILURE, payload: error.message });
  }
}

function* watchLoadCatalogues() {
  yield takeLatest(CATALOGUES.REQUEST, loadCataloguesAsync);
}

function* watchCreateCatalogueUser() {
  yield takeLatest(CREATE_CATALOGUE_USER.REQUEST, createCatalogueUserAsync);
}

function* watchCreateContactUser() {
  yield takeLatest(CREATE_CONTACT_USER.REQUEST, createContactUserAsync);
}

function* watchCreateCustomer() {
  yield takeLatest(CREATE_CUSTOMER.REQUEST, createCustomerAsync);
}

function* watchCreateCustomerBusiness() {
  yield takeLatest(CREATE_CUSTOMER_BUSINESS.REQUEST, createCustomerBusinessAsync);
}

function* watchCreateShippingDetails() {
  yield takeLatest(CREATE_SHIPPING_DETAILS.REQUEST, createShippingDetailsAsync);
}

function* watchCreateBillingDetails() {
  yield takeLatest(CREATE_BILLING_DETAILS.REQUEST, createBillingDetailsAsync);
}

function* watchGetSegments() {
  yield takeLatest(SEGMENTS.REQUEST, getSegmentsAsync);
}

function* watchCreateCustomerDelivery() {
  yield takeLatest(CREATE_CUSTOMER_DELIVERY.REQUEST, createCustomerDeliveryAsync);
}

function* watchCreateCustomerPickup() {
  yield takeLatest(CREATE_CUSTOMER_PICKUP.REQUEST, createCustomerPickUpAsync);
}

function* watchCreateCustomerRewards() {
  yield takeLatest(CREATE_CUSTOMER_REWARDS.REQUEST, createCustomerRewardsAsync);
}

function* watchGetOpcoLocation() {
  yield takeLatest(OPCO_LOCATION.REQUEST, getOpcoLocationAsync);
}

function* watchGetLabelTranslation() {
  yield takeLatest(LABEL_TRANSLATION.REQUEST, getLabelTranslationAsync);
}

export default function* rootSaga() {
  yield all([
    watchLoadCatalogues(),
    watchCreateCatalogueUser(),
    watchCreateContactUser(),
    watchCreateCustomer(),
    watchCreateCustomerBusiness(),
    watchCreateShippingDetails(),
    watchCreateBillingDetails(),
    watchGetSegments(),
    watchCreateCustomerDelivery(),
    watchCreateCustomerPickup(),
    watchCreateCustomerRewards(),
    watchGetOpcoLocation(),
    watchGetLabelTranslation(),
  ]);
}
