import React from 'react';
import { Row, Col, Modal, Button } from 'antd';
import { RightOutlined } from '@ant-design/icons';

import '../assets/css/Home.css';
import '../assets/css/Contact.css';

const ContactThankYou = ({
  contactUsThankYouLabelTranslation,
  contactUsThankYouModalVisible,
  setContactUsThankYouModalVisible,
  clearContactUsFormData,
  contactUserIsSuccess,
  contactUserId,
}) => {
  const handleContactClose = (e) => {
    e.preventDefault();
    setContactUsThankYouModalVisible(false);
    clearContactUsFormData();
  };

  return (
    <Modal
      className="contactus-model"
      title=""
      centered
      visible={contactUsThankYouModalVisible}
      onOk={() => setContactUsThankYouModalVisible(false)}
      onCancel={() => setContactUsThankYouModalVisible(false)}
      width={1000}
      footer={null}
      maskClosable={false}
      keyboard={false}
    >
      <Row className="">
        <Col span={24} className="thankyou-wraper">
          <h1 className="">{contactUsThankYouLabelTranslation?.contact_us_thank_you_modal_header}</h1>
          <p>{contactUsThankYouLabelTranslation?.contact_us_thank_you_modal_text}</p>
          <p>
            {contactUsThankYouLabelTranslation?.contact_us_thank_you_reference_id}
            {contactUserIsSuccess ? contactUserId : null}
          </p>
          <Button className="catelog-btn" type="primary" onClick={(e) => handleContactClose(e)}>
            {contactUsThankYouLabelTranslation?.contact_us_thank_you_close}
            <RightOutlined />
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default ContactThankYou;
