/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Modal, Steps, Form, Button } from 'antd';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { CheckOutlined } from '@ant-design/icons';
import MaskedInput from 'antd-mask-input';
import postalCodes from 'postal-codes-js';
import { createCustomer } from '../actions';
import {
  EMPTY_STRING,
  LANG_CLASS_NAME,
  LANGUAGE,
  MAX_LENGTH_BUSINESS_NAME,
  MAX_LENGTH_NAME,
  EMAIL_VALIDATION_PATTERN,
  TEXT_VALIDATION_PATTERN,
  MAX_LENGTH_EMAIL,
  PHONE_NUMBER_LENGTH,
  INVALID_POSTALCODE_NAME,
  MAX_LENGTH_POSTAL_CODE,
  POSTAL_CODE_MASK,
  POSTAL_CODE_PLACEHOLDER,
} from '../constants/appConstants';
import Error from '../components/error';
import PhoneInputField from '../components/PhoneInputField';
import InputField from '../components/InputField';

import 'react-phone-input-2/lib/style.css';
import '../assets/css/AboutYou.css';
import '../assets/css/Newcustomer.css';

const LOADING_DEFAULT_LATENCY = process.env.REACT_APP_LOADING_DEFAULT_LATENCY;
const SALES_REP_POSTAL_CODE_API_KEY = process.env.REACT_APP_SALES_REP_POSTAL_CODE_API_KEY;

const { Step } = Steps;

const AboutYouForm = (props) => {
  const {
    aboutYouLabelTranslation,
    language,
    setLanguage,
    newCustomerModalVisible,
    customerUserId,
    isSyscoRep,
    billingHeaderVisible,
    setNewCustomerModalVisible,
    setCloseConfirmationVisible,
    setOnboardingBusinessModalVisible,
    setCustomerEmail,
    setCustomerPhone,
    customerPhone,
    customerEmail,
    clearOnBoardingFormData,
    isModalClosed,
    setIsModalClosed,
    customerType,
    handleWorkedWithSyscoRepToggleStyling,
    hasWorkedWithSyscoRep,
    setCustomerFirstName,
    setCustomerLastName,
    setCustomerBusinessName,
    setHasWorkedWithSyscoRep,
    setSyscoAssociateName,
    setHasNotWorkedWithSyscoRep,
    customerFirstName,
    customerLastName,
    customerBusinessName,
    syscoAssociateName,
    hasNotWorkedWithSyscoRep,
    customerResError,
    setRedirectModalVisible,
    setCustomerLoadingState,
    customerLoadingState,
    getFieldDecorator,
    form,
    setCustomerResIsSuccess,
    validateFields,
    isSalesRepPostalCodeError,
    setIsSalesRepPostalCodeError,
    salesRepPostalCode,
    setSalesRepPostalCode,
    gclid,
    utmSource,
    utmMedium,
    utmCampaign,
    utmContent,
  } = props;

  const dispatch = useDispatch();

  const CANADA_WEB_POST_API_TIME = process.env.REACT_APP_CANADA_WEB_POST_API_TIME;

  const [postalCodeErrorMsg, setPostalCodeErrorMsg] = useState(EMPTY_STRING);

  function loadPostalCode() {
    if (
      window.pca !== null &&
      window.pca.Address !== null &&
      window.pca !== undefined &&
      window.pca.Address !== undefined
    ) {
      const fields = [{ element: 'salesRepPostcode', field: 'PostalCode' }];
      const options = {
        key: SALES_REP_POSTAL_CODE_API_KEY,
      };
      const control = new window.pca.Address(fields, options);
      control.load();
    }
  }
  useEffect(() => {
    if (isSyscoRep) {
      loadPostalCode();
    }
  }, [isSyscoRep]);

  const handleHasWorkedWithSyscoRepName = (event) => {
    event.preventDefault();
    setHasWorkedWithSyscoRep(true);
    setHasNotWorkedWithSyscoRep(false);
  };

  const handleHasNotWorkedWithSyscoRepName = (event) => {
    event.preventDefault();
    setHasNotWorkedWithSyscoRep(true);
    setHasWorkedWithSyscoRep(false);
  };

  const customerResErrorRef = useRef(customerResError);

  const loadBusinessModal = _.debounce((customerResErrorRefVal) => {
    if (!customerResErrorRefVal.current) {
      if (isSyscoRep && !customerResError) {
        setCustomerLoadingState(false);
        setNewCustomerModalVisible(false);
        setOnboardingBusinessModalVisible(true);
        setCustomerResIsSuccess(false);
      }
    }
  }, LOADING_DEFAULT_LATENCY);

  const validateSalesRepPostalCode = (postalCode) => {
    if (
      postalCode?.trim() === EMPTY_STRING ||
      postalCode?.trim().length < MAX_LENGTH_POSTAL_CODE ||
      postalCodes.validate('CA', `${postalCode}`) !== true
    ) {
      setIsSalesRepPostalCodeError(true);
      setPostalCodeErrorMsg(aboutYouLabelTranslation?.error_msg_billing_postal_code);
    } else {
      setIsSalesRepPostalCodeError(false);
      setPostalCodeErrorMsg(EMPTY_STRING);
    }
  };

  const updateSalesRepPostalCode = _.debounce(() => {
    setSalesRepPostalCode(document.getElementById('salesRepPostcode').value);
    validateSalesRepPostalCode(document.getElementById('salesRepPostcode')?.value);
  }, CANADA_WEB_POST_API_TIME);

  const handleSalesRepPostalCode = (e) => {
    if (e.target.value.trim().length < MAX_LENGTH_POSTAL_CODE) {
      setIsSalesRepPostalCodeError(true);
      setPostalCodeErrorMsg(aboutYouLabelTranslation?.error_msg_billing_postal_code);
    } else {
      setIsSalesRepPostalCodeError(false);
      setPostalCodeErrorMsg(EMPTY_STRING);
      setSalesRepPostalCode(e.target.value);
      updateSalesRepPostalCode();
    }
  };

  const handleCustomerSubmit = (e) => {
    e.preventDefault();
    validateSalesRepPostalCode(salesRepPostalCode);
    let isValidPostalCodeCheck = true;
    if (isSyscoRep) {
      isValidPostalCodeCheck =
        !isSalesRepPostalCodeError &&
        document.getElementById('salesRepPostcode')?.value?.trim() !== EMPTY_STRING &&
        document.getElementById('salesRepPostcode')?.value?.trim().length === MAX_LENGTH_POSTAL_CODE;
    }
    form.validateFields(
      [
        'about-first-name',
        'about-last-name',
        'about-business-name',
        'about-email-name',
        'about-phone',
        'about-sysco-assoc-name',
      ],
      (err) => {
        if (!err) {
          if (
            isValidPostalCodeCheck &&
            customerFirstName !== EMPTY_STRING &&
            customerLastName !== EMPTY_STRING &&
            customerBusinessName !== EMPTY_STRING &&
            customerEmail !== EMPTY_STRING &&
            customerPhone !== EMPTY_STRING &&
            customerPhone.length === PHONE_NUMBER_LENGTH
          ) {
            const customerData = {
              userId: !isModalClosed ? customerUserId : EMPTY_STRING,
              firstName: customerFirstName,
              lastName: customerLastName,
              businessName: customerBusinessName,
              email: customerEmail,
              phone: customerPhone,
              workedWithSyscoAssociate: hasWorkedWithSyscoRep,
              syscoAssociateName,
              customerOption: customerType,
              postalCode: isSyscoRep ? salesRepPostalCode : EMPTY_STRING,
              isModelClosed: false,
              gclid,
              utmSource,
              utmMedium,
              utmCampaign,
              utmContent,
              lang: language,
            };
            setIsModalClosed(false);
            setCustomerLoadingState(true);
            dispatch(createCustomer(customerData));
            loadBusinessModal(customerResErrorRef);
          }
        }
      }
    );
  };

  useEffect(() => {
    customerResErrorRef.current = customerResError;
  }, [customerResError]);

  const validateFieldValue = (fieldName) => {
    validateFields([fieldName], (errors) => {
      if (errors) {
        form.resetFields([fieldName]);
      }
    });
  };

  const validateAllField = () => {
    validateFieldValue('about-first-name');
    validateFieldValue('about-last-name');
    validateFieldValue('about-business-name');
    validateFieldValue('about-email-name');
    validateFieldValue('about-phone');
    validateFieldValue('about-sysco-assoc-name');
  };

  return (
    <Modal
      className="contactus-model"
      title=""
      centered
      visible={newCustomerModalVisible}
      onOk={() => {
        setNewCustomerModalVisible(false);
      }}
      onCancel={() => {
        setCloseConfirmationVisible(true);
      }}
      width={1200}
      footer={null}
      maskClosable={false}
      keyboard={false}
      forceRender
    >
      <Row className="model-body">
        {isSyscoRep ? (
          <React.Fragment>
            <Col
              span={8}
              xs={24}
              sm={24}
              md={8}
              lg={8}
              xl={6}
              className={isSyscoRep ? 'model-header modelheader-syscorep' : 'model-header'}
            >
              <p className="newcustomer-heading-become newcustomer-heading-become-desktop">
                {isSyscoRep
                  ? aboutYouLabelTranslation?.steps_become_a_customer
                  : aboutYouLabelTranslation?.steps_become_a_customer_default}
              </p>
            </Col>

            <Col
              span={isSyscoRep ? 9 : 12}
              xs={15}
              sm={15}
              md={12}
              lg={12}
              xl={14}
              className={'model-header ncob-steps model-header-rep'}
            >
              <Steps size="small" current={0} className={'sysco-rep'}>
                <Step title={aboutYouLabelTranslation?.steps_about_you} />
                {isSyscoRep ? <Step title={aboutYouLabelTranslation?.steps_your_business} /> : null}
                {!isSyscoRep ? (
                  <Step title={aboutYouLabelTranslation?.steps_shipping_address} />
                ) : null}
                {billingHeaderVisible && !isSyscoRep ? (
                  <Step title={aboutYouLabelTranslation?.steps_your_billing_address} />
                ) : null}
                {!isSyscoRep ? <Step title={aboutYouLabelTranslation?.steps_shipping} /> : null}
                {!isSyscoRep ? <Step title={aboutYouLabelTranslation?.steps_rewards_finish} /> : null}
              </Steps>
            </Col>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Col xs={15} sm={15} md={20} lg={20} xl={20} className="model-header">
              <p className="newcustomer-heading">
                {' '}
                {isSyscoRep
                  ? aboutYouLabelTranslation?.steps_become_a_customer
                  : aboutYouLabelTranslation?.steps_become_a_customer_default}
              </p>
            </Col>
          </React.Fragment>
        )}
        <Col span={9} xs={9} sm={9} md={4} lg={4} xl={4} className="model-header">
          <span
            className={
              isSyscoRep
                ? 'language-selection-rep'
                : billingHeaderVisible
                ? 'Language-selection-newcustomer Language-selection-newcustomer-billing'
                : 'Language-selection-newcustomer Language-selection-newcustomer-without-billing'
            }
          >
            <a
              href="#!"
              className={language === LANGUAGE.ENGLISH ? LANG_CLASS_NAME : EMPTY_STRING}
              onClick={() => {
                setLanguage(LANGUAGE.ENGLISH);
                validateAllField();
              }}
            >
              EN
            </a>
            <span> I </span>
            <a
              href="#!"
              className={language === LANGUAGE.FRENCH ? LANG_CLASS_NAME : EMPTY_STRING}
              onClick={() => {
                setLanguage(LANGUAGE.FRENCH);
                validateAllField();
              }}
            >
              FR
            </a>
          </span>
        </Col>

        <Col xs={24} sm={24} md={24} lg={14} xl={12} className="col-left-newcustomer">
          <Col span={24} className="col-leftside-aboutyou-model">
            {!customerResError ? (
              <Form
                target="blank"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleCustomerSubmit(e);
                }}
              >
                <Col span={12} className="industry-text">
                  <InputField
                    isPatternRequried
                    isSize={false}
                    getFieldDecorator={getFieldDecorator}
                    value={customerFirstName}
                    setValue={setCustomerFirstName}
                    maxLength={MAX_LENGTH_NAME}
                    isRequired
                    patternFormat={TEXT_VALIDATION_PATTERN}
                    labelName={aboutYouLabelTranslation?.about_you_first_name}
                    errorMsg={aboutYouLabelTranslation?.error_about_you_first_name_required}
                    patternErrorMsg={aboutYouLabelTranslation?.error_about_you_first_name_invalid}
                    idValue="about-first-name"
                    isNotEmail
                  />
                </Col>

                <Col span={12} className="industry-text">
                  <InputField
                    isPatternRequried
                    isSize={false}
                    getFieldDecorator={getFieldDecorator}
                    value={customerLastName}
                    setValue={setCustomerLastName}
                    maxLength={MAX_LENGTH_NAME}
                    isRequired
                    patternFormat={TEXT_VALIDATION_PATTERN}
                    labelName={aboutYouLabelTranslation?.about_you_last_name}
                    errorMsg={aboutYouLabelTranslation?.error_about_you_last_name_required}
                    patternErrorMsg={aboutYouLabelTranslation?.error_about_you_last_name_invalid}
                    idValue="about-last-name"
                    isNotEmail
                  />
                </Col>

                <Col span={24} className="industry-text">
                  <InputField
                    isPatternRequried
                    isSize={false}
                    getFieldDecorator={getFieldDecorator}
                    value={customerBusinessName}
                    setValue={setCustomerBusinessName}
                    maxLength={MAX_LENGTH_BUSINESS_NAME}
                    isRequired
                    patternFormat={TEXT_VALIDATION_PATTERN}
                    labelName={aboutYouLabelTranslation?.about_you_business_name}
                    errorMsg={aboutYouLabelTranslation?.error_about_you_business_required}
                    patternErrorMsg={aboutYouLabelTranslation?.error_about_you_business_invalid}
                    idValue="about-business-name"
                    isNotEmail
                  />
                </Col>
                {isSyscoRep ? (
                  <Col span={24} className="industry-text industry-text">
                    <label className="catelog-lable">{aboutYouLabelTranslation?.billing_postal_code}</label>
                    <Form.Item>
                      <MaskedInput
                        id="salesRepPostcode"
                        className={isSalesRepPostalCodeError ? 'input-has-error' : 'ant-input-lg'}
                        placeholder={aboutYouLabelTranslation?.billing_postal_code}
                        value={salesRepPostalCode}
                        onChange={(e) => handleSalesRepPostalCode(e)}
                        onBlur={() => {
                          updateSalesRepPostalCode();
                          setIsSalesRepPostalCodeError(false);
                          setPostalCodeErrorMsg(EMPTY_STRING);
                        }}
                        maxLength={MAX_LENGTH_POSTAL_CODE}
                        title={INVALID_POSTALCODE_NAME}
                        autoComplete="off"
                        mask={POSTAL_CODE_MASK}
                        placeholderChar={POSTAL_CODE_PLACEHOLDER}
                      />
                    </Form.Item>
                    {isSalesRepPostalCodeError ? <span className="error-msg">{postalCodeErrorMsg}</span> : EMPTY_STRING}
                  </Col>
                ) : null}

                <Col span={12} className="industry-text">
                  <InputField
                    isPatternRequried
                    isSize={false}
                    getFieldDecorator={getFieldDecorator}
                    value={customerEmail}
                    setValue={setCustomerEmail}
                    maxLength={MAX_LENGTH_EMAIL}
                    isRequired
                    patternFormat={EMAIL_VALIDATION_PATTERN}
                    labelName={aboutYouLabelTranslation?.about_you_email}
                    errorMsg={aboutYouLabelTranslation?.error_about_you_email_required}
                    patternErrorMsg={aboutYouLabelTranslation?.error_about_you_email_invalid}
                    idValue="about-email-name"
                    isNotEmail={false}
                  />
                </Col>

                <Col span={12} className="industry-text about-phone-number">
                  <label className="catelog-lable">{aboutYouLabelTranslation?.about_you_phone}</label>
                  <PhoneInputField
                    className="phone-number"
                    phoneNo={customerPhone}
                    setPhoneNo={setCustomerPhone}
                    getFieldDecorator={getFieldDecorator}
                    isRequired
                    errorMsg={aboutYouLabelTranslation?.error_about_you_phone_required}
                    minErrorMsg={aboutYouLabelTranslation?.error_about_you_phone_invalid}
                    idValue="about-phone"
                  />
                </Col>

                <Col span={24} className="">
                  <label className="catelog-lable">{aboutYouLabelTranslation?.about_you_have_you_work}</label>
                </Col>
                <Col span={12}>
                  <div
                    className={handleWorkedWithSyscoRepToggleStyling(hasWorkedWithSyscoRep)}
                    onClick={(e) => handleHasWorkedWithSyscoRepName(e)}
                  >
                    <CheckOutlined className="order-now-delivery" />
                    {aboutYouLabelTranslation?.about_you_yes}
                  </div>
                </Col>
                <Col span={12} className="">
                  <div
                    className={handleWorkedWithSyscoRepToggleStyling(hasNotWorkedWithSyscoRep)}
                    onClick={(e) => handleHasNotWorkedWithSyscoRepName(e)}
                  >
                    <CheckOutlined className="order-now-delivery" />
                    {aboutYouLabelTranslation?.about_you_no}
                  </div>
                </Col>
                {hasWorkedWithSyscoRep ? (
                  <Col span={24} className="">
                    <InputField
                      isPatternRequried
                      isSize={false}
                      getFieldDecorator={getFieldDecorator}
                      value={syscoAssociateName}
                      setValue={setSyscoAssociateName}
                      maxLength={MAX_LENGTH_NAME}
                      isRequired={false}
                      patternFormat={TEXT_VALIDATION_PATTERN}
                      labelName={aboutYouLabelTranslation?.about_you_sysco_associate_name}
                      errorMsg={aboutYouLabelTranslation?.error_about_you_sales_rep_required}
                      patternErrorMsg={aboutYouLabelTranslation?.error_about_you_sales_rep_required}
                      idValue="about-sysco-assoc-name"
                      isNotEmail
                    />
                  </Col>
                ) : null}
                <Col xs={12} sm={12} md={14} lg={6} xl={6} className="industry-text">
                  <Button
                    loading={customerLoadingState}
                    className="catelog-btn"
                    type="submit"
                    htmlType="submit"
                    target="_blank"
                  >
                    {`${aboutYouLabelTranslation?.about_you_continue} >`}
                  </Button>
                </Col>
              </Form>
            ) : (
              <Error errorLabelTranslation={aboutYouLabelTranslation} />
            )}
          </Col>
        </Col>
        <Col xs={24} sm={24} md={24} lg={11} xl={12} className="col-rightside-newcustomer">
          <Col span={22} id="associate-help">
            <p>
              {aboutYouLabelTranslation?.new_customer_select_option_sysco_repo_msg}
            </p>
          </Col>
          <Col span={24} className="col-rightside-newcustomer-box">
            <h1 className="newcustomer-rightside-text">
              {aboutYouLabelTranslation?.new_customer_benefits_of_an_account}
            </h1>
            <Row>
              <Col span={3} className="">
                <img src={require('../assets/Images/qa.png')} />
              </Col>
              <Col span={21} className="">
                {' '}
                <p>{aboutYouLabelTranslation?.new_customer_quality_assurance}</p>
              </Col>
              <Col span={24} className="">
                <hr className="menu-divider"></hr>
              </Col>
            </Row>
            <Row>
              <Col span={3} className="">
                {' '}
                <img src={require('../assets/Images/cs.png')} />
              </Col>
              <Col span={21} className="">
                {' '}
                <p>{aboutYouLabelTranslation?.new_customer_customer_service}</p>
              </Col>
              <Col span={24} className="">
                <hr className="menu-divider"></hr>
              </Col>
            </Row>
            <Row>
              <Col span={3} className="">
                {' '}
                <img src={require('../assets/Images/ont.png')} />
              </Col>
              <Col span={21} className="">
                {' '}
                <p>{aboutYouLabelTranslation?.new_customer_ordering_inventory}</p>
              </Col>
              <Col span={24} className="">
                <hr className="menu-divider"></hr>
              </Col>
            </Row>
            <Row>
              <Col span={3} className="">
                {' '}
                <img alt="syscoadvantage" src={require('../assets/Images/syscoadvantage.png')} />
              </Col>
              <Col span={21} className="">
                {' '}
                <p>{aboutYouLabelTranslation?.new_customer_sysco_advantage}</p>
              </Col>
              <Col span={24} className="">
                <hr className="menu-divider"></hr>
              </Col>
            </Row>
            <Row>
              <Col span={3} className="">
                {' '}
                <img alt="menu" src={require('../assets/Images/menu.png')} />
              </Col>
              <Col span={21} className="">
                {' '}
                <p>{aboutYouLabelTranslation?.new_customer_menu_marketing_services}</p>
              </Col>
              <Col span={24} className="">
                <hr className="menu-divider"></hr>
              </Col>
            </Row>
            <span>
              <p className="qesstion-call">{aboutYouLabelTranslation?.new_customer_questions}</p>
              <a> {aboutYouLabelTranslation?.new_customer_call} </a>
            </span>
          </Col>
        </Col>
      </Row>
    </Modal>
  );
};

export default AboutYouForm;
