import React, { useRef, useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { Row, Col, Modal, Button, Steps, Form, Input, Select, Checkbox } from 'antd';
import MaskedInput from 'antd-mask-input';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';

import {
  LANG_CLASS_NAME,
  LANGUAGE,
  EMPTY_STRING,
  INVALID_POSTALCODE_NAME,
  MAX_LENGTH_CITY,
  MAX_LENGTH_POSTAL_CODE,
  MAX_LENGTH_STREET_ADDRESS,
  PROVINCE_LIST_OPTION,
  PO_BOX,
  POSTAL_CODE_MASK,
  POSTAL_CODE_PLACEHOLDER,
} from '../constants/appConstants';
import { createBillingDetails } from '../actions';
import Error from '../components/error';

import '../assets/css/NewCustomerAddress.css';
import InputField from '../components/InputField';

const LOADING_DEFAULT_LATENCY = process.env.REACT_APP_LOADING_DEFAULT_LATENCY;

const { Step } = Steps;

const BillingDetails = ({
  billingLabelTranslation,
  language,
  setLanguage,
  onboardingBillingModalVisible,
  setOnboardingBillingModalVisible,
  setCloseConfirmationVisible,
  billingHeaderVisible,
  billingAddress,
  setBillingAddress,
  billingCity,
  setBillingCity,
  setBillingPostalCode,
  billingPostalCode,
  billingProvince,
  billingConfirm,
  setBillingConfirm,
  setBillingProvince,
  setOnboardingShippingModalVisible,
  customerUserId,
  isModalClosed,
  billingResError,
  setShippingTypeModalVisible,
  setBillingLoadingState,
  billingLoadingState,
  form,
  getFieldDecorator,
  isProvinceError,
  setIsProvinceError,
  isPostalCodeError,
  setIsPostalCodeError,
  isStreetAddressError,
  setIsStreetAddressError,
}) => {
  const dispatch = useDispatch();

  const CANADA_WEB_POST_API_TIME = process.env.REACT_APP_CANADA_WEB_POST_API_TIME;

  const [provinceErrorMsg, setProvinceErrorMsg] = useState(EMPTY_STRING);
  const [postalCodeErrorMsg, setPostalCodeErrorMsg] = useState(EMPTY_STRING);
  const [streetAddressErrorMsg, setStreetAddressErrorMsg] = useState(EMPTY_STRING);

  const handleBillingDetailsBackButton = () => {
    setOnboardingBillingModalVisible(false);
    setOnboardingShippingModalVisible(true);
  };

  const billingResErrorRef = useRef(billingResError);

  const loadShippingTypeModal = _.debounce((billingResErrorRef) => {
    if (!billingResErrorRef.current) {
      setBillingLoadingState(false);
      setOnboardingBillingModalVisible(false);
      setShippingTypeModalVisible(true);
    }
  }, LOADING_DEFAULT_LATENCY);

  const validateBillingFieldValue = (fieldName) => {
    form.validateFields([fieldName], (errors) => {
      if (errors) {
        form.resetFields([fieldName]);
      }
    });
  };

  const clearAllBillingFields = () => {
    validateBillingFieldValue('billingStreetAddress');
    validateBillingFieldValue('billingStreetCity');
    validateBillingFieldValue('billingConfirmation');
    validateBillingFieldValue('billingProvince');
    setStreetAddressErrorMsg(EMPTY_STRING);
    setIsStreetAddressError(false);
    setIsProvinceError(false);
    setProvinceErrorMsg(EMPTY_STRING);
    setIsPostalCodeError(false);
    setPostalCodeErrorMsg(EMPTY_STRING);
  };

  const validateBillingAddress = (streetAddress) => {
    if (streetAddress?.trim().toLowerCase().startsWith(PO_BOX)) {
      setStreetAddressErrorMsg(billingLabelTranslation?.error_msg_billing_street_po_box);
      setIsStreetAddressError(true);
    } else {
      setStreetAddressErrorMsg(EMPTY_STRING);
      setIsStreetAddressError(false);
    }
  };

  const validateBillingPostalCode = (postalCode) => {
    if (postalCode?.trim() === EMPTY_STRING || postalCode?.trim().length < MAX_LENGTH_POSTAL_CODE) {
      setIsPostalCodeError(true);
      setPostalCodeErrorMsg(billingLabelTranslation?.error_msg_billing_postal_code);
    } else {
      setIsPostalCodeError(false);
      setPostalCodeErrorMsg(EMPTY_STRING);
    }
  };

  const validateBillingProvince = (provinceName) => {
    if (
      provinceName !== undefined &&
      !_.isEmpty(provinceName?.trim()) &&
      !PROVINCE_LIST_OPTION.some((province) => province?.toLowerCase() === provinceName?.toLowerCase())
    ) {
      setProvinceErrorMsg(billingLabelTranslation?.errro_message_shipping__province);
      setIsProvinceError(true);
    } else {
      setProvinceErrorMsg(EMPTY_STRING);
      setIsProvinceError(false);
    }
  };

  const handleBillingDetailsSubmit = () => {
    validateBillingAddress(billingAddress);
    validateBillingPostalCode(billingPostalCode);
    validateBillingProvince(billingProvince);

    form.validateFields(
      ['billingStreetAddress', 'billingStreetCity', 'billingConfirmation', 'billingProvince'],
      (err) => {
        if (!err) {
          if (
            !isPostalCodeError &&
            !isProvinceError &&
            !isStreetAddressError &&
            document.getElementById('billingPostcode')?.value?.trim() !== EMPTY_STRING &&
            document.getElementById('billingPostcode')?.value?.trim().length === MAX_LENGTH_POSTAL_CODE &&
            PROVINCE_LIST_OPTION.filter((province) =>
              province.toLowerCase().includes(form.getFieldValue('billingProvince')?.toLowerCase())
            ) &&
            billingConfirm &&
            billingPostalCode !== EMPTY_STRING &&
            billingProvince !== EMPTY_STRING &&
            billingCity !== EMPTY_STRING &&
            billingAddress !== EMPTY_STRING
          ) {
            const billingDetailsData = {
              userId: customerUserId,
              isModelClosed: isModalClosed,
              streetAddress: billingAddress,
              city: billingCity,
              province: billingProvince?.trim(),
              postalCode: billingPostalCode?.trim(),
              lang: language,
            };
            setBillingLoadingState(true);
            dispatch(createBillingDetails(billingDetailsData));
            loadShippingTypeModal(billingResErrorRef);
          }
        }
      }
    );
  };

  useEffect(() => {
    billingResErrorRef.current = billingResError;
  }, [billingResError]);

  const updateBillingAddress = _.debounce(() => {
    setBillingPostalCode(document.getElementById('billingPostcode')?.value);
    setBillingAddress(document.getElementById('billingStreetId')?.value);
    setBillingCity(document.getElementById('billingCityId')?.value);
    setBillingProvince(document.getElementById('billingProvinceId')?.value);

    form.setFieldsValue({
      billingStreetAddress: document.getElementById('billingStreetId')?.value,
    });
    form.setFieldsValue({
      billingStreetCity: document.getElementById('billingCityId')?.value,
    });
    form.setFieldsValue({
      billingProvince: document.getElementById('billingProvinceId')?.value,
    });
    validateBillingAddress(form.getFieldValue('billingStreetAddress'));
    validateBillingPostalCode(document.getElementById('billingPostcode')?.value);
    validateBillingProvince(form.getFieldValue('billingProvince'));
  }, CANADA_WEB_POST_API_TIME);

  const handleBillingPostalCode = (e) => {
    if (e.target.value.trim().length < MAX_LENGTH_POSTAL_CODE) {
      setIsPostalCodeError(true);
      setPostalCodeErrorMsg(billingLabelTranslation?.error_msg_billing_postal_code);
    } else {
      setIsPostalCodeError(false);
      setPostalCodeErrorMsg(EMPTY_STRING);
      setBillingPostalCode(e.target.value);
      updateBillingAddress();
    }
  };

  return (
    <Modal
      className="contactus-model"
      title=""
      centered
      visible={onboardingBillingModalVisible}
      onOk={() => {
        setOnboardingBillingModalVisible(false);
        clearAllBillingFields();
      }}
      onCancel={() => {
        setCloseConfirmationVisible(true);
        clearAllBillingFields();
      }}
      width={1200}
      footer={null}
      maskClosable={false}
      keyboard={false}
      forceRender
    >
      <Row className="model-body">
        <Col xs={24} sm={24} md={8} lg={6} xl={6} className="model-header">
          <p className="newcustomer-heading">{billingLabelTranslation?.steps_become_a_customer_default}</p>
        </Col>
        <Col xs={15} sm={15} md={12} lg={14} xl={14} className="model-header ncob-steps">
          <Steps size="small" current={3}>
            <Step title={billingLabelTranslation?.steps_about_you} />
            <Step title={billingLabelTranslation?.steps_your_business} />
            <Step title={billingLabelTranslation?.steps_shipping_address} />
            {billingHeaderVisible ? <Step title={billingLabelTranslation?.steps_your_billing_address} /> : null}
            <Step title={billingLabelTranslation?.steps_shipping} />
            <Step title={billingLabelTranslation?.steps_rewards_finish} />
          </Steps>
        </Col>
        <Col xs={9} sm={9} md={4} lg={4} xl={4} className="model-header">
          <span
            className={
              billingHeaderVisible
                ? 'Language-selection-newcustomer Language-selection-newcustomer-billing'
                : 'Language-selection-newcustomer Language-selection-newcustomer-without-billing'
            }
          >
            <a
              href="#!"
              className={language === LANGUAGE.ENGLISH ? LANG_CLASS_NAME : EMPTY_STRING}
              onClick={() => {
                setLanguage(LANGUAGE.ENGLISH);
                clearAllBillingFields();
              }}
            >
              EN
            </a>
            <span> I </span>
            <a
              href="#!"
              className={language === LANGUAGE.FRENCH ? LANG_CLASS_NAME : EMPTY_STRING}
              onClick={() => {
                setLanguage(LANGUAGE.FRENCH);
                clearAllBillingFields();
              }}
            >
              FR
            </a>
          </span>
        </Col>
        {!billingResError ? (
          <React.Fragment>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={14}
              xl={14}
              className="col-leftside-newcustomer-address shippingdetails-form-wrap"
            >
              <Col span={24} className="industry-text">
                <label className="catelog-lable">{billingLabelTranslation?.billing_postal_code}</label>
                <Form.Item>
                  <MaskedInput
                    id="billingPostcode"
                    className={isPostalCodeError ? 'input-has-error' : EMPTY_STRING}
                    placeholder={billingLabelTranslation?.billing_postal_code}
                    value={billingPostalCode}
                    onChange={(e) => handleBillingPostalCode(e)}
                    onBlur={() => {
                      updateBillingAddress();
                      setIsPostalCodeError(false);
                      setPostalCodeErrorMsg(EMPTY_STRING);
                    }}
                    maxLength={MAX_LENGTH_POSTAL_CODE}
                    title={INVALID_POSTALCODE_NAME}
                    autoComplete="off"
                    mask={POSTAL_CODE_MASK}
                    placeholderChar={POSTAL_CODE_PLACEHOLDER}
                  />
                </Form.Item>
                {isPostalCodeError ? <span className="error-msg">{postalCodeErrorMsg}</span> : EMPTY_STRING}
              </Col>
              <input id="billingStreetId" hidden />
              <input id="billingCityId" hidden />
              <input id="billingProvinceId" hidden />
              <Col span={24} className="industry-text">
                <label className="catelog-lable">{billingLabelTranslation?.billing_street_address}</label>
                <Form.Item>
                  {getFieldDecorator('billingStreetAddress', {
                    rules: [
                      {
                        required: true,
                        message: billingLabelTranslation?.error_msg_billing_streert_address,
                      },
                    ],
                  })(
                    <Input
                      id="billingStreetAddress"
                      className={isStreetAddressError ? 'input-has-error' : EMPTY_STRING}
                      placeholder={billingLabelTranslation?.billing_street_address}
                      value={billingAddress}
                      onChange={(e) => {
                        setBillingAddress(e.target.value);
                        validateBillingAddress(e.target.value);
                      }}
                      maxLength={MAX_LENGTH_STREET_ADDRESS}
                    />
                  )}
                </Form.Item>
                {isStreetAddressError ? <span className="error-msg">{streetAddressErrorMsg}</span> : EMPTY_STRING}
              </Col>
              <Col span={24} className="industry-text">
                <InputField
                  isPatternRequried={false}
                  isSize={false}
                  getFieldDecorator={getFieldDecorator}
                  value={billingCity}
                  setValue={setBillingCity}
                  maxLength={MAX_LENGTH_CITY}
                  isRequired
                  patternFormat={EMPTY_STRING}
                  labelName={billingLabelTranslation?.billing_city}
                  errorMsg={billingLabelTranslation?.error_msg_billing_city}
                  patternErrorMsg={billingLabelTranslation?.error_msg_billing_city}
                  idValue="billingStreetCity"
                  isNotEmail
                />
              </Col>
              <Col span={24} className="industry-text province-select">
                <label className="catelog-lable">{billingLabelTranslation?.billing_province}</label>
                <Form.Item>
                  {getFieldDecorator('billingProvince', {
                    rules: [
                      {
                        required: true,
                        message: billingLabelTranslation?.error_msg_billing_province,
                      },
                    ],
                  })(
                    <Select
                      className={`form-select form-select-shipping-address  ${isProvinceError ? 'has-error' : null}`}
                      placeholder={billingLabelTranslation?.business_select_option}
                      value={billingProvince}
                      onChange={(value) => {
                        setBillingProvince(value?.trim());
                        validateBillingProvince(value?.trim());
                      }}
                    >
                      {PROVINCE_LIST_OPTION?.map((option) => (
                        <Select.Option key={option} value={option}>
                          {option}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                  {isProvinceError ? (
                    <div className="has-error error-msg ant-form-explain">{provinceErrorMsg}</div>
                  ) : null}
                </Form.Item>
              </Col>

              <Col span={24} className="industry-text billing-verify-checkbox">
                <Form.Item>
                  {getFieldDecorator('billingConfirmation', {
                    rules: [
                      {
                        required: true,
                        transform: (value) => value || undefined, // Those two lines
                        type: 'boolean',
                        message: billingLabelTranslation?.error_msg_billing_checkbox,
                      },
                    ],
                  })(
                    <Checkbox checked={billingConfirm} onChange={(e) => setBillingConfirm(e.target.checked)}>
                      {billingLabelTranslation?.billing_verify_msg}
                    </Checkbox>
                  )}
                </Form.Item>
              </Col>
            </Col>

            <Col span="24" className="yourbusiness-footer">
              <Button
                disabled={billingLoadingState}
                className="form-btn-link btn-back"
                type="primary"
                onClick={() => handleBillingDetailsBackButton()}
              >
                {' '}
                <LeftOutlined />
                {billingLabelTranslation?.billing_back}
              </Button>
              <span className="linkSeparator"></span>
              <Button
                loading={billingLoadingState}
                className="catelog-btn"
                type="primary"
                onClick={() => handleBillingDetailsSubmit()}
              >
                {billingLabelTranslation?.billing_continue}
                <RightOutlined />
              </Button>
            </Col>
          </React.Fragment>
        ) : (
          <Error errorLabelTranslation={billingLabelTranslation} />
        )}
      </Row>
    </Modal>
  );
};

export default BillingDetails;
