import React from 'react';

import '../assets/css/Error.css';

const Error = ({ errorLabelTranslation }) => {
  return (
    <div className="div-error">
      <h1 className="h1-error-label">{errorLabelTranslation?.error_main_message}</h1>
      <h3>{errorLabelTranslation?.error_secondary_message}</h3>
    </div>
  );
};

export default Error;
