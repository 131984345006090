const initialState = {
  catalogues: {
    fetching: false,
    data: {
      totalItems: null,
      items: [],
    },
    error: null,
  },
  catalogueUser: {
    fetching: false,
    data: {
      isSuccess: false,
      catalogueUrl: '',
      userId: '',
    },
  },
  contactUser: {
    fetching: false,
    data: {
      isSuccess: false,
      userId: '',
    },
  },

  customer: {
    fetching: false,
    data: {
      isSuccess: false,
      userId: '',
    },
    error: false,
  },

  customerBusiness: {
    fetching: false,
    data: {
      isSuccess: false,
      userId: '',
    },
    error: false,
  },

  shippingDetails: {
    fetching: false,
    data: {
      isSuccess: false,
      userId: '',
    },
    error: false,
  },

  billingDetails: {
    fetching: false,
    data: {
      isSuccess: false,
      userId: '',
    },
    error: false,
  },

  segments: {
    fetching: false,
    data: {
      total: null,
      items: [],
    },
    error: false,
  },

  customerDelivery: {
    fetching: false,
    data: {
      isSuccess: false,
      userId: '',
    },
    error: false,
  },

  opcoLocation: {
    fetching: false,
    data: {
      opcoId: '',
      opcoName: '',
      opcoAddress: '',
      distance: '',
    },
    error: '',
  },

  customerPickUp: {
    fetching: false,
    data: {
      isSuccess: false,
      userId: '',
    },
    error: false,
  },

  customerRewards: {
    fetching: false,
    data: {
      isSuccess: false,
      userId: '',
    },
    error: false,
  },

  labelNames: {
    fetching: false,
    data: {
      isSuccess: false,
      labelNames: [],
    },
    error: false,
  },
};

export default initialState;
