import React from 'react';
import { Form } from 'antd';

import PhoneInput from 'react-phone-input-2';
import {
  PHONE_NUMBER_COUNTRY_CODE,
  PHONE_NUMBER_MASK,
  PHONE_NUMBER_PLACE_HOLDER,
  PHONE_NUMBER_LENGTH,
} from '../constants/appConstants';
import '../assets/css/PhoneInputField.css';

function PhoneInputField(props) {
  const { getFieldDecorator, phoneNo, setPhoneNo, isRequired, errorMsg, minErrorMsg, idValue } = props;

  return (
    <Form.Item>
      {getFieldDecorator(`${idValue}`, {
        rules: [
          {
            required: isRequired,
            message: errorMsg,
          },
          { min: PHONE_NUMBER_LENGTH, message: minErrorMsg },
        ],
      })(
        <PhoneInput
          id={idValue}
          className="input-first"
          country={PHONE_NUMBER_COUNTRY_CODE}
          onlyCountries={[PHONE_NUMBER_COUNTRY_CODE]}
          placeholder={PHONE_NUMBER_PLACE_HOLDER}
          value={phoneNo}
          onChange={(value) => setPhoneNo(value)}
          masks={{ ca: PHONE_NUMBER_MASK }}
          disableDropdown
          disableCountryCode
        />
      )}
    </Form.Item>
  );
}

export default PhoneInputField;
