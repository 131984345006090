import React from 'react';
import { Row, Col } from 'antd';
import '../assets/css/EmptyCatalogues.css'

class EmptyCatalogues extends React.Component {
  render() {
    return <h2 className="empty-catalogues-text">There are no catalogues available at the moment.</h2>;
  }
}

export default EmptyCatalogues;
