import React from 'react';
import { Button, Row, Col, Menu } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import {
  LANG_CLASS_NAME,
  EMPTY_STRING,
  SYSCO_CANADA_URLS,
  SYSCO_BUSINESS_ORDER,
  LANGUAGE,
} from '../constants/appConstants';

const Header = ({ language, setLanguage, setNewCustomerModalVisible, headerLabelTranslation, utmUrl }) => {
  return (
    <Row className="section-1 sysco-header">
      <Col xs={24} sm={24} md={4} lg={3} xl={3}>
        <img src={require('../assets/Images/Sysco-Logo-Color1.png')} className="home-header-logo" />
      </Col>
      <Col xs={24} sm={24} md={10} lg={11} xl={11} className="header-sec-2">
        <Menu mode="horizontal">
          <SubMenu
            key="header_about"
            title={headerLabelTranslation?.header_about}
            className="nav-item"
            key="header_company_profile"
          >
            <Menu.ItemGroup title={headerLabelTranslation?.header_company_profile}>
              <Menu.Item key="header_sysco_story">
                <a href={`${SYSCO_CANADA_URLS?.STORY_URL}${utmUrl}`} target="_blank" rel="noopener noreferrer">
                  {headerLabelTranslation?.header_sysco_story}
                </a>
              </Menu.Item>
              <Menu.Item key="header_our_purpose">
                <a href={`${SYSCO_CANADA_URLS?.PURPOSE_URL}${utmUrl}`} target="_blank" rel="noopener noreferrer">
                  {headerLabelTranslation?.header_our_purpose}
                </a>
              </Menu.Item>
              <Menu.Item key="header_csr">
                <a href={`${SYSCO_CANADA_URLS?.CSR_URL}${utmUrl}`} target="_blank" rel="noopener noreferrer">
                  {headerLabelTranslation?.header_csr}
                </a>
              </Menu.Item>
              <Menu.Item key="header_annual_report">
                <a href={`${SYSCO_CANADA_URLS?.ANNUAL_REPORTS_URL}${utmUrl}`} target="_blank" rel="noopener noreferrer">
                  {headerLabelTranslation?.header_annual_report}
                </a>
              </Menu.Item>
            </Menu.ItemGroup>
            <hr className="menu-divider"></hr>
            <Menu.ItemGroup title={headerLabelTranslation?.header_our_people} key="header_our_people">
              <Menu.Item key="header_canadian_leadership_team">
                <a href={`${SYSCO_CANADA_URLS?.MANAGEMENT_URL}${utmUrl}`} target="_blank" rel="noopener noreferrer">
                  {headerLabelTranslation?.header_canadian_leadership_team}
                </a>
              </Menu.Item>
              <Menu.Item key="header_diversity">
                <a href={`${SYSCO_CANADA_URLS?.DIVERSITY_URL}${utmUrl}`} target="_blank" rel="noopener noreferrer">
                  {headerLabelTranslation?.header_diversity}
                </a>
              </Menu.Item>
            </Menu.ItemGroup>
            <hr className="menu-divider"></hr>
            <Menu.ItemGroup title={headerLabelTranslation?.header_sysco_today} key="header_sysco_today">
              <Menu.Item key="header_news_room">
                <a href={`${SYSCO_CANADA_URLS?.NEWS_ROOM_URL}${utmUrl}`} target="_blank" rel="noopener noreferrer">
                  {headerLabelTranslation?.header_news_room}
                </a>
              </Menu.Item>
              <Menu.Item key="header_covid_19">
                <a href={`${SYSCO_CANADA_URLS?.CORONAVIRUS_URL}${utmUrl}`} target="_blank" rel="noopener noreferrer">
                  {headerLabelTranslation?.header_covid_19}
                </a>
              </Menu.Item>
            </Menu.ItemGroup>
          </SubMenu>
          <SubMenu key="header_product" title={headerLabelTranslation?.header_product} className="nav-item">
            <Menu.ItemGroup title={headerLabelTranslation?.header_products}>
              <Menu.Item key="header_product_categories">
                <a
                  href={`${SYSCO_CANADA_URLS?.PRODUCT_CATEGORIES_URL}${utmUrl}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {headerLabelTranslation?.header_product_categories}
                </a>
              </Menu.Item>
              <Menu.Item key="header_product_catalogues">
                <a href={`${SYSCO_CANADA_URLS?.CATALOGUES_URL}${utmUrl}`} target="_blank" rel="noopener noreferrer">
                  {headerLabelTranslation?.header_product_catalogues}
                </a>
              </Menu.Item>
              <Menu.Item key="header_sysco_brand_family">
                <a href={`${SYSCO_CANADA_URLS?.BRAND_FAMILY_URL}${utmUrl}`} target="_blank" rel="noopener noreferrer">
                  {headerLabelTranslation?.header_sysco_brand_family}
                </a>
              </Menu.Item>
              <Menu.Item key="header_quality_assurance">
                <a
                  href={`${SYSCO_CANADA_URLS?.QUALITY_ASSUARANCE_URL}${utmUrl}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {headerLabelTranslation?.header_quality_assurance}
                </a>
              </Menu.Item>
            </Menu.ItemGroup>
          </SubMenu>
          <SubMenu key="header_service" title={headerLabelTranslation?.header_service} className="nav-item">
            <Menu.ItemGroup title={headerLabelTranslation?.header_technology_sol}>
              <Menu.Item key="header_sysco_source_ordering">
                <a
                  href={`${SYSCO_CANADA_URLS.SYSCO_TECH_SOLUTIONS_URL}${utmUrl}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {headerLabelTranslation?.header_sysco_source_ordering}
                </a>
              </Menu.Item>
              <Menu.Item key="header_sysco_delivery">
                <a href={`${SYSCO_CANADA_URLS?.SYSCO_DELIVERY_URL}${utmUrl}`} target="_blank" rel="noopener noreferrer">
                  {headerLabelTranslation?.header_sysco_delivery}
                </a>
              </Menu.Item>
            </Menu.ItemGroup>
            <hr className="menu-divider"></hr>
            <Menu.ItemGroup
              title={headerLabelTranslation?.header_value_added_services}
              key="header_value_added_services"
            >
              <Menu.Item key="header_sysco_advantage">
                <a href={`${SYSCO_CANADA_URLS?.ADVANTAGE_URL}${utmUrl}`} target="_blank" rel="noopener noreferrer">
                  {headerLabelTranslation?.header_sysco_advantage}
                </a>
              </Menu.Item>
              <Menu.Item key="header_menu_marketing">
                <a href={`${SYSCO_CANADA_URLS?.MARKETING_URL}${utmUrl}`} target="_blank" rel="noopener noreferrer">
                  {headerLabelTranslation?.header_menu_marketing}
                </a>
              </Menu.Item>
              <Menu.Item key="header_business_resources">
                <a
                  href={`${SYSCO_CANADA_URLS?.BUSINESS_RESOURCES_URL}${utmUrl}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {headerLabelTranslation?.header_business_resources}
                </a>
              </Menu.Item>
            </Menu.ItemGroup>
            <hr className="menu-divider"></hr>
            <Menu.ItemGroup title={headerLabelTranslation?.header_solutions} key="header_solutions">
              <Menu.Item key="header_the_sysco_difference">
                {' '}
                <a href={`${SYSCO_CANADA_URLS?.DIFFERENCE_URL}${utmUrl}`} target="_blank" rel="noopener noreferrer">
                  {headerLabelTranslation?.header_the_sysco_difference}
                </a>
              </Menu.Item>
              <Menu.Item key="header_foodie_solution_toolkit">
                <a href={`${SYSCO_CANADA_URLS?.FOODIE_URL}${utmUrl}`} target="_blank" rel="noopener noreferrer">
                  {headerLabelTranslation?.header_foodie_solution_toolkit}
                </a>
              </Menu.Item>
              <Menu.Item key="header_business_boosters">
                <a
                  href={`${SYSCO_CANADA_URLS?.BUSINESS_BOOSTERS_URL}${utmUrl}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {headerLabelTranslation?.header_business_boosters}
                </a>
              </Menu.Item>
            </Menu.ItemGroup>

            <hr className="menu-divider"></hr>
            <Menu.ItemGroup title={headerLabelTranslation?.header_digital_resources} key="header_digital_resources">
              <Menu.Item key="header_sysco_virtual_kitchen">
                <a
                  href={`${SYSCO_CANADA_URLS?.VIRTUAL_KITCHEN_URL}${utmUrl}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {headerLabelTranslation?.header_sysco_virtual_kitchen}
                </a>
              </Menu.Item>
              <Menu.Item key="header_podcasts">
                <a href={`${SYSCO_CANADA_URLS?.PODCASTS_URL}${utmUrl}`} target="_blank" rel="noopener noreferrer">
                  {headerLabelTranslation?.header_podcasts}
                </a>
              </Menu.Item>
            </Menu.ItemGroup>
          </SubMenu>
          <SubMenu key="header_foodie" title={headerLabelTranslation?.header_foodie} className="nav-item">
            <Menu.ItemGroup title={headerLabelTranslation?.header_foodie}>
              <Menu.Item key="header_foodies_unite">
                <a href={`${SYSCO_CANADA_URLS?.FOODIES_UNITE_URL}${utmUrl}`} target="_blank" rel="noopener noreferrer">
                  {headerLabelTranslation?.header_foodies_unite}
                </a>
              </Menu.Item>
              <Menu.Item key="header_foodie_magazine">
                <a
                  href={`${SYSCO_CANADA_URLS?.FOODIE_MAGAZINE_URL}${utmUrl}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {headerLabelTranslation?.header_foodie_magazine}
                </a>
              </Menu.Item>
              <Menu.Item key="header_foodie_swag">
                <a href={`${SYSCO_CANADA_URLS?.MERCHANDISE_URL}${utmUrl}`} target="_blank" rel="noopener noreferrer">
                  {headerLabelTranslation?.header_foodie_swag}
                </a>
              </Menu.Item>
            </Menu.ItemGroup>
          </SubMenu>
          <Menu.Item key="header_rewards">
            <a
              href={`${SYSCO_CANADA_URLS?.REWARDS_URL}${utmUrl}`}
              target="_blank"
              rel="noopener noreferrer"
              className="menu-link"
            >
              {headerLabelTranslation?.header_rewards}
            </a>
          </Menu.Item>
          <SubMenu key="SubMenu6" title={headerLabelTranslation?.header_industries} className="nav-item">
            <Menu.ItemGroup title={headerLabelTranslation?.header_who_we_are}>
              <Menu.Item key="header_restaurants">
                <a
                  href={`${SYSCO_CANADA_URLS?.RESTAURANT_SUPPLIER_URL}${utmUrl}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {headerLabelTranslation?.header_restaurants}
                </a>
              </Menu.Item>
              <Menu.Item key="header_education">
                <a
                  href={`${SYSCO_CANADA_URLS?.SCHOOL_FOOD_SUPPLIER_URL}${utmUrl}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {headerLabelTranslation?.header_education}
                </a>
              </Menu.Item>
              <Menu.Item key="header_government">
                <a
                  href={`${SYSCO_CANADA_URLS?.GOVERNMENT_FOOD_SUPPLIER_URL}${utmUrl}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {headerLabelTranslation?.header_government}
                </a>
              </Menu.Item>
              <Menu.Item key="header_tourism">
                <a
                  href={`${SYSCO_CANADA_URLS?.TOURISM_FOOD_SUPPLIER_URL}${utmUrl}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {headerLabelTranslation?.header_tourism}
                </a>
              </Menu.Item>
              <Menu.Item key="header_hospitality">
                <a
                  href={`${SYSCO_CANADA_URLS?.HOSPITALITY_FOOD_SUPPLIER_URL}${utmUrl}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {headerLabelTranslation?.header_hospitality}
                </a>
              </Menu.Item>
            </Menu.ItemGroup>
            <hr className="menu-divider"></hr>
            <Menu.ItemGroup title={headerLabelTranslation?.header_health_care}>
              <Menu.Item key="header_who_we_are_health">
                <a href={`${SYSCO_CANADA_URLS?.WHO_WE_ARE_URL}${utmUrl}`} target="_blank" rel="noopener noreferrer">
                  {headerLabelTranslation?.header_who_we_are_health}
                </a>
              </Menu.Item>
              <Menu.Item key="header_what_we_do_health">
                <a href={`${SYSCO_CANADA_URLS?.WHAT_WE_DO_URL}${utmUrl}`} target="_blank" rel="noopener noreferrer">
                  {headerLabelTranslation?.header_what_we_do_health}
                </a>
              </Menu.Item>
              <Menu.Item key="header_synergy_tech_suite">
                <a
                  href={`${SYSCO_CANADA_URLS?.SYNERGY_TECH_SUITE_URL}${utmUrl}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {headerLabelTranslation?.header_synergy_tech_suite}
                </a>
              </Menu.Item>
              <Menu.Item key="header_product_features">
                <a
                  href={`${SYSCO_CANADA_URLS?.PRODUCT_FEATURES_RECIPES_URL}${utmUrl}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {headerLabelTranslation?.header_product_features}
                </a>
              </Menu.Item>
              <Menu.Item key="header_covid_19_resources">
                <a href={`${SYSCO_CANADA_URLS?.HEALTHCARE_URL}${utmUrl}`} target="_blank" rel="noopener noreferrer">
                  {headerLabelTranslation?.header_covid_19_resources}
                </a>
              </Menu.Item>
              <Menu.Item key="header_nourishing_news">
                <a
                  href={`${SYSCO_CANADA_URLS?.NOURISHING_NEWS_URL}${utmUrl}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {headerLabelTranslation?.header_nourishing_news}
                </a>
              </Menu.Item>
              <Menu.Item key="header_nutrition_resources">
                <a
                  href={`${SYSCO_CANADA_URLS?.NUTRITION_RESOURCES_URL}${utmUrl}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {headerLabelTranslation?.header_nutrition_resources}
                </a>
              </Menu.Item>
            </Menu.ItemGroup>
          </SubMenu>
          <SubMenu key="header_careers" title={headerLabelTranslation?.header_careers} className="nav-item">
            <Menu.ItemGroup title={headerLabelTranslation?.header_work_at_sysco}>
              <Menu.Item key="header_culture_and_benefits">
                <a
                  href={`${SYSCO_CANADA_URLS?.CULTURE_AND_BENEFITS_URL}${utmUrl}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {headerLabelTranslation?.header_culture_and_benefits}
                </a>
              </Menu.Item>
              <Menu.Item key="header_career_path">
                <a href={`${SYSCO_CANADA_URLS?.OUR_DEPARTMENT_URL}${utmUrl}`} target="_blank" rel="noopener noreferrer">
                  {headerLabelTranslation?.header_career_path}
                </a>
              </Menu.Item>
            </Menu.ItemGroup>
            <hr className="menu-divider"></hr>
            <Menu.ItemGroup title={headerLabelTranslation?.header_featured_careers}>
              <Menu.Item key="header_delivery_driver">
                <a href={`${SYSCO_CANADA_URLS?.DELIVERY_URL}${utmUrl}`} target="_blank" rel="noopener noreferrer">
                  {headerLabelTranslation?.header_delivery_driver}
                </a>
              </Menu.Item>
              <Menu.Item key="header_Warehouse_industrial_athlete">
                <a
                  href={`${SYSCO_CANADA_URLS?.INDUSTRIAL_ATHLETE_URL}${utmUrl}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {headerLabelTranslation?.header_Warehouse_industrial_athlete}
                </a>
              </Menu.Item>
              <Menu.Item key="header_specialty_meat_and_seafood">
                <a
                  href={`${SYSCO_CANADA_URLS?.MEAT_AND_SEAFOOD_URL}${utmUrl}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {headerLabelTranslation?.header_specialty_meat_and_seafood}
                </a>
              </Menu.Item>
              <hr className="menu-divider"></hr>
              <Menu.Item key="header_find_next_career">
                <a href={`${SYSCO_CANADA_URLS?.CAREERS_URL}${utmUrl}`} target="_blank" rel="noopener noreferrer">
                  {headerLabelTranslation?.header_find_next_career}
                </a>
              </Menu.Item>
            </Menu.ItemGroup>
          </SubMenu>
          <SubMenu key="header_conducts" title={headerLabelTranslation?.header_conducts} className="nav-item">
            <Menu.ItemGroup title={headerLabelTranslation?.header_contact}>
              <Menu.Item key="header_order_now">
                <a href={`${SYSCO_CANADA_URLS?.ORDER_NOW_URL}${utmUrl}`} target="_blank" rel="noopener noreferrer">
                  {headerLabelTranslation?.header_order_now}
                </a>
              </Menu.Item>
              <Menu.Item key="header_contact_us">
                <a href={`${SYSCO_CANADA_URLS?.CONTACT_US_URL}${utmUrl}`} target="_blank" rel="noopener noreferrer">
                  {headerLabelTranslation?.header_contact_us}
                </a>
              </Menu.Item>
              <Menu.Item key="header_our_locations">
                <a href={`${SYSCO_CANADA_URLS?.OUR_LOCATIONS_URL}${utmUrl}`} target="_blank" rel="noopener noreferrer">
                  {headerLabelTranslation?.header_our_locations}
                </a>
              </Menu.Item>
            </Menu.ItemGroup>
          </SubMenu>
        </Menu>
      </Col>
      <Col xs={24} sm={24} md={10} lg={10} xl={10} className="header-sec-3 header-sec-3-mobile">
        <div className="header-right">
          <span className="Language-selection">
            <a
              href="#!"
              className={language === LANGUAGE.ENGLISH ? LANG_CLASS_NAME : EMPTY_STRING}
              onClick={() => setLanguage(LANGUAGE.ENGLISH)}
            >
              EN
            </a>
            <span> I </span>
            <a
              href="#!"
              className={language === LANGUAGE.FRENCH ? LANG_CLASS_NAME : EMPTY_STRING}
              onClick={() => setLanguage(LANGUAGE.FRENCH)}
            >
              FR
            </a>
          </span>
          <Button type="primary" onClick={() => setNewCustomerModalVisible(true)}>
            {headerLabelTranslation?.header_create_customer}
          </Button>
          <Button type="link" className="btn-business-link" href={SYSCO_BUSINESS_ORDER} target="_blank">
            {headerLabelTranslation?.header_business_order}
          </Button>
        </div>
      </Col>
    </Row>
  );
};

export default Header;
