import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Modal, Form, Button } from 'antd';
import { createCatalogueUser } from '../actions';
import {
  EMPTY_STRING,
  TEXT_VALIDATION_PATTERN,
  EMAIL_VALIDATION_PATTERN,
  PHONE_NUMBER_LENGTH,
  MAX_LENGTH_NAME,
  MAX_LENGTH_EMAIL,
} from '../constants/appConstants';
import PhoneInputField from '../components/PhoneInputField';
import InputField from '../components/InputField';

import 'react-phone-input-2/lib/style.css';
import '../assets/css/Catalogues.css';
import CatalogueThankYou from './CataloguesThankyou';

const Catalogues = (props) => {
  const dispatch = useDispatch();

  const {
    catalogueLabelTranslation,
    language,
    catalogueDownloadModalVisible,
    setCatalogueDownloadModalVisible,
    catalogueToShow,
    catalogueImageBaseUrl,
    form,
    gclid,
    utmSource,
    utmMedium,
    utmCampaign,
    utmContent,
  } = props;

  const { resetFields, getFieldDecorator } = form;

  const [catalogueDownloadThankYouModalVisible, setCatalogueDownloadThankYouModalVisible] = useState(false);

  const [name, setName] = useState(EMPTY_STRING);
  const [email, setEmail] = useState(EMPTY_STRING);
  const [phoneNo, setPhoneNo] = useState(null);

  const handleSubmitValues = (catalogueId, languageVal, catalogueFormData, e, url) => {
    e.preventDefault();
    setCatalogueDownloadModalVisible(false);
    setCatalogueDownloadThankYouModalVisible(true);
    dispatch(createCatalogueUser(catalogueId, languageVal, catalogueFormData));
    window.open(url);
  };

  const handleSubmit = (catalogueId, languageVal, e, url) => {
    e.preventDefault();
    form.validateFieldsAndScroll((err) => {
      if (!err) {
        if (phoneNo === null || phoneNo.length === PHONE_NUMBER_LENGTH) {
          const catalogueFormData = {
            name,
            phone: phoneNo,
            email,
            gclid,
            utmSource,
            utmMedium,
            utmCampaign,
            utmContent,
            lang: language,
          };
          handleSubmitValues(catalogueId, languageVal, catalogueFormData, e, url);
        }
      }
    });
  };

  const handleValidationsOnClose = () => {
    resetFields();
    form.setFieldsValue({ catalogueName: name, catalogueEmailName: email, catalougePhone: phoneNo });
  };

  return (
    <React.Fragment>
      <Modal
        className="contactus-model"
        title=""
        centered
        visible={catalogueDownloadModalVisible}
        onOk={() => {
          handleValidationsOnClose();
          setCatalogueDownloadModalVisible(false);
        }}
        onHide
        onCancel={() => {
          handleValidationsOnClose();
          setCatalogueDownloadModalVisible(false);
        }}
        width={1000}
        footer={null}
        maskClosable={false}
        keyboard={false}
      >
        <Row className="">
          <Col xs={24} sm={24} md={24} lg={9} xl={9} className="col-leftside-catalogue">
            <img
              className="leftside-image"
              alt={`${catalogueImageBaseUrl}`}
              src={`${catalogueImageBaseUrl}${catalogueToShow.imageUrl}`}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={15} xl={15} className="col-rightside-catalogues">
            <h1 className="dowload-text">
              {catalogueLabelTranslation?.catalogue_download} {catalogueToShow?.catalogueName}
            </h1>
            <h3>{catalogueLabelTranslation?.catalogue_fill_info_msg}</h3>
            <form
              className="catelogue-field-wrap"
              target="blank"
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(catalogueToShow.catalogueId, language, e, catalogueToShow.catalogueUrl);
              }}
            >
              <InputField
                isPatternRequried
                getFieldDecorator={getFieldDecorator}
                value={name}
                setValue={setName}
                maxLength={MAX_LENGTH_NAME}
                isRequired
                patternFormat={TEXT_VALIDATION_PATTERN}
                labelName={catalogueLabelTranslation?.catalogue_user_name}
                errorMsg={catalogueLabelTranslation?.error_contact_us_name_required}
                patternErrorMsg={catalogueLabelTranslation?.error_contact_us_name_invalid}
                idValue="catalogueName"
                isNotEmail
                isSize
              />
              <InputField
                isPatternRequried
                isSize
                getFieldDecorator={getFieldDecorator}
                value={email}
                setValue={setEmail}
                maxLength={MAX_LENGTH_EMAIL}
                isRequired
                patternFormat={EMAIL_VALIDATION_PATTERN}
                labelName={catalogueLabelTranslation?.catalogue_user_email}
                errorMsg={catalogueLabelTranslation?.error_about_you_email_required}
                patternErrorMsg={catalogueLabelTranslation?.error_about_you_email_invalid}
                idValue="catalogueEmailName"
                isNotEmail={false}
              />
              <label className="catelog-lable">{catalogueLabelTranslation?.catalogue_user_phone}</label>
              <div className="catelogue-field-phone">
                <PhoneInputField
                  phoneNo={phoneNo}
                  setPhoneNo={setPhoneNo}
                  getFieldDecorator={getFieldDecorator}
                  isRequired={false}
                  errorMsg={catalogueLabelTranslation?.error_about_you_phone_required}
                  minErrorMsg={catalogueLabelTranslation?.error_about_you_phone_invalid}
                  idValue="catalougePhone"
                />
              </div>
              <Button className="catelog-btn" type="submit" target="_blank" htmlType="submit">
                {`${catalogueLabelTranslation?.catalogue_download} >`}
              </Button>
            </form>
          </Col>
        </Row>
      </Modal>
      <CatalogueThankYou
        catalogueThankYouLabelTranslation={catalogueLabelTranslation}
        catalogueDownloadThankYouModalVisible={catalogueDownloadThankYouModalVisible}
        setCatalogueDownloadThankYouModalVisible={setCatalogueDownloadThankYouModalVisible}
        catalogueImageBaseUrl={catalogueImageBaseUrl}
        catalogueToShow={catalogueToShow}
      />
    </React.Fragment>
  );
};

export default Form.create()(Catalogues);
