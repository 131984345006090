import { createRequestTypes } from '../reduxHelpers/index';

const CATALOGUES = createRequestTypes('CATALOGUES');
const CREATE_CATALOGUE_USER = createRequestTypes('CREATE_CATALOGUE_USER');
const CREATE_CONTACT_USER = createRequestTypes('CREATE_CONTACT_USER');
const CREATE_CUSTOMER = createRequestTypes('CREATE_CUSTOMER');
const CREATE_CUSTOMER_BUSINESS = createRequestTypes('CREATE_CUSTOMER_BUSINESS');
const CREATE_SHIPPING_DETAILS = createRequestTypes('CREATE_SHIPPING_DETAILS');
const CREATE_BILLING_DETAILS = createRequestTypes('CREATE_BILLING_DETAILS');
const CREATE_CUSTOMER_DELIVERY = createRequestTypes('CREATE_CUSTOMER_DELIVERY');
const CREATE_CUSTOMER_PICKUP = createRequestTypes('CREATE_CUSTOMER_PICKUP');
const CREATE_CUSTOMER_REWARDS = createRequestTypes('CREATE_CUSTOMER_REWARDS');

const OPCO_LOCATION = createRequestTypes('OPCO_LOCATION');
const SEGMENTS = createRequestTypes('SEGMENTS');
const LABEL_TRANSLATION = createRequestTypes('LABEL_TRANSLATION');

export {
  CATALOGUES,
  CREATE_CATALOGUE_USER,
  CREATE_CONTACT_USER,
  CREATE_CUSTOMER,
  CREATE_CUSTOMER_BUSINESS,
  CREATE_SHIPPING_DETAILS,
  CREATE_BILLING_DETAILS,
  CREATE_CUSTOMER_REWARDS,
  SEGMENTS,
  CREATE_CUSTOMER_DELIVERY,
  CREATE_CUSTOMER_PICKUP,
  OPCO_LOCATION,
  LABEL_TRANSLATION,
};
