import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Modal, Form, Button } from 'antd';
import _ from 'lodash';
import {
  MAX_LENGTH_BUSINESS_NAME,
  MAX_LENGTH_QUESTION,
  MAX_LENGTH_NAME,
  EMAIL_VALIDATION_PATTERN,
  TEXT_VALIDATION_PATTERN,
  EMPTY_STRING,
  PHONE_NUMBER_LENGTH,
  MAX_LENGTH_EMAIL,
} from '../constants/appConstants';
import { createContactUser } from '../actions';
import ContactThankYou from './ContactThankyou';

import 'react-phone-input-2/lib/style.css';
import '../assets/css/Contact.css';
import PhoneInputField from '../components/PhoneInputField';
import InputField from '../components/InputField';

const LOADING_DEFAULT_LATENCY = process.env.REACT_APP_LOADING_DEFAULT_LATENCY;

const ContactUsForm = (props) => {
  const dispatch = useDispatch();

  const {
    form,
    contactUsLabelTranslation,
    contactUsModalVisible,
    setContactUsModalVisible,
    gclid,
    utmSource,
    utmMedium,
    utmCampaign,
    utmContent,
    language,
  } = props;

  const { resetFields, getFieldDecorator } = form;

  const [contactUsThankYouModalVisible, setContactUsThankYouModalVisible] = useState(false);

  const [contactName, setContactName] = useState(EMPTY_STRING);
  const [contactPhoneNo, setContactPhoneNo] = useState(EMPTY_STRING);
  const [contactEmail, setContactEmail] = useState(EMPTY_STRING);
  const [contactBusinessName, setContactBusinessName] = useState(EMPTY_STRING);
  const [contactQuestion, setContactQuestion] = useState(EMPTY_STRING);
  const [contactLoadingState, setContactLoadingState] = useState(false);

  const {
    data: { isSuccess: contactUserIsSuccess, userId: contactUserId },
  } = useSelector((state) => state.contactUser);

  const clearContactUsFormData = () => {
    setContactName(EMPTY_STRING);
    setContactPhoneNo(EMPTY_STRING);
    setContactEmail(EMPTY_STRING);
    setContactBusinessName(EMPTY_STRING);
    setContactQuestion(EMPTY_STRING);
    resetFields();
  };

  const loadSContactUsThankYouModal = _.debounce(() => {
    setContactLoadingState(false);
    setContactUsModalVisible(false);
    setContactUsThankYouModalVisible(true);
    clearContactUsFormData();
  }, LOADING_DEFAULT_LATENCY);

  const handleContactSubmit = (e) => {
    e.preventDefault();
    form.validateFieldsAndScroll((err) => {
      if (!err) {
        if (
          contactQuestion !== EMPTY_STRING &&
          contactName !== EMPTY_STRING &&
          contactPhoneNo !== EMPTY_STRING &&
          contactEmail !== EMPTY_STRING &&
          contactBusinessName !== EMPTY_STRING &&
          contactPhoneNo.length === PHONE_NUMBER_LENGTH
        ) {
          const contactData = {
            name: contactName,
            phone: contactPhoneNo,
            email: contactEmail,
            businessName: contactBusinessName,
            questions: contactQuestion,
            gclid,
            utmSource,
            utmMedium,
            utmCampaign,
            utmContent,
            lang: language,
          };
          setContactLoadingState(true);
          dispatch(createContactUser(contactData));
          loadSContactUsThankYouModal();
        }
      }
    });
  };

  return (
    <React.Fragment>
      <Modal
        className="contactus-model"
        title=""
        centered
        visible={contactUsModalVisible}
        onOk={() => setContactUsModalVisible(false)}
        onCancel={() => {
          resetFields();
          setContactUsModalVisible(false);
        }}
        width={1000}
        footer={null}
        maskClosable={false}
        keyboard={false}
      >
        <Row className="">
          <Form onSubmit={(e) => handleContactSubmit(e)} className="contactus-form">
            <Col xs={24} sm={24} md={24} lg={12} xl={12} className="col-leftside-contact">
              <h1 className="download-text-contact ">{contactUsLabelTranslation?.contact_us_modal_header}</h1>
              <h3>{contactUsLabelTranslation?.contact_us_modal_text}</h3>

              <InputField
                isPatternRequried
                getFieldDecorator={getFieldDecorator}
                value={contactName}
                setValue={setContactName}
                maxLength={MAX_LENGTH_NAME}
                isRequired
                patternFormat={TEXT_VALIDATION_PATTERN}
                labelName={contactUsLabelTranslation?.contact_us_name}
                errorMsg={contactUsLabelTranslation?.error_contact_us_name_required}
                patternErrorMsg={contactUsLabelTranslation?.error_contact_us_name_invalid}
                idValue="contact-us-name"
                isNotEmail
                isSize
              />

              <div className="contact-phone-field">
                <label className="catelog-lable">{contactUsLabelTranslation?.contact_us_phone}</label>
                <PhoneInputField
                  phoneNo={contactPhoneNo}
                  setPhoneNo={setContactPhoneNo}
                  getFieldDecorator={getFieldDecorator}
                  isRequired
                  errorMsg={contactUsLabelTranslation?.error_about_you_phone_required}
                  minErrorMsg={contactUsLabelTranslation?.error_about_you_phone_invalid}
                  idValue="contact-us-phone"
                />
              </div>

              <InputField
                isPatternRequried
                isSize
                getFieldDecorator={getFieldDecorator}
                value={contactBusinessName}
                setValue={setContactBusinessName}
                maxLength={MAX_LENGTH_BUSINESS_NAME}
                isRequired
                patternFormat={TEXT_VALIDATION_PATTERN}
                labelName={contactUsLabelTranslation?.contact_us_business_name}
                errorMsg={contactUsLabelTranslation?.error_about_you_business_required}
                patternErrorMsg={contactUsLabelTranslation?.error_about_you_business_invalid}
                idValue="contact-business-name"
                isNotEmail
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} className="col-rightside col-rightside-form">
              <InputField
                isPatternRequried
                isSize
                getFieldDecorator={getFieldDecorator}
                value={contactEmail}
                setValue={setContactEmail}
                maxLength={MAX_LENGTH_EMAIL}
                isRequired
                patternFormat={EMAIL_VALIDATION_PATTERN}
                labelName={contactUsLabelTranslation?.contact_us_email}
                errorMsg={contactUsLabelTranslation?.error_about_you_email_required}
                patternErrorMsg={contactUsLabelTranslation?.error_about_you_email_invalid}
                idValue="contact-email-name"
                isNotEmail={false}
              />
              <InputField
                isPatternRequried
                isSize={false}
                getFieldDecorator={getFieldDecorator}
                value={contactQuestion}
                setValue={setContactQuestion}
                maxLength={MAX_LENGTH_QUESTION}
                isRequired
                patternFormat={TEXT_VALIDATION_PATTERN}
                labelName={contactUsLabelTranslation?.contact_us_question}
                errorMsg={contactUsLabelTranslation?.error_contact_us_question_required}
                patternErrorMsg={contactUsLabelTranslation?.error_contact_us_question_invalid}
                idValue="contact-question"
                isNotEmail
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={124} className="contact-submit-btn">
              <Button loading={contactLoadingState} className="catelog-btn" type="submit" htmlType="submit">
                {`${contactUsLabelTranslation?.contact_us_submit} >`}
              </Button>
            </Col>
          </Form>
        </Row>
      </Modal>

      <ContactThankYou
        contactUsThankYouLabelTranslation={contactUsLabelTranslation}
        contactUsThankYouModalVisible={contactUsThankYouModalVisible}
        setContactUsThankYouModalVisible={setContactUsThankYouModalVisible}
        clearContactUsFormData={clearContactUsFormData}
        contactUserIsSuccess={contactUserIsSuccess}
        contactUserId={contactUserId}
      />
    </React.Fragment>
  );
};

export default Form.create()(ContactUsForm);
