import { EMPTY_STRING, TEXT_VALIDATION_PATTERN, MAX_LENGTH_POSTAL_CODE } from '../constants/appConstants';

const validateAndAddFields = (field, validation, isPostalCodeError) => {
  const ValidData = {};
  const condition =
    validation === 'postalCode'
      ? !isPostalCodeError && field.length === MAX_LENGTH_POSTAL_CODE
      : field.match(TEXT_VALIDATION_PATTERN);
  if (field && condition && field.trim() !== EMPTY_STRING) {
    ValidData[`${validation}`] = field;
  }
  return ValidData;
};

export default validateAndAddFields;
