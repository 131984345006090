import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
const antIcon = <LoadingOutlined style={{ fontSize: 44 }} spin />;


function AppLoader({ show }) {
  return (
    <div className={show ? 'app-loader show' : 'app-loader'}>
      <div className="loader-wrap">
        <span className="loader"></span>
      </div>
    </div>
  );
}

export default AppLoader;
