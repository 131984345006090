import { action } from '../reduxHelpers/index';
import {
  CATALOGUES,
  CREATE_CATALOGUE_USER,
  CREATE_CONTACT_USER,
  CREATE_CUSTOMER,
  CREATE_CUSTOMER_BUSINESS,
  CREATE_SHIPPING_DETAILS,
  CREATE_BILLING_DETAILS,
  SEGMENTS,
  CREATE_CUSTOMER_DELIVERY,
  CREATE_CUSTOMER_PICKUP,
  CREATE_CUSTOMER_REWARDS,
  OPCO_LOCATION,
  LABEL_TRANSLATION,
} from './actionTypes';

const loadCatalogues = (language) => action(CATALOGUES.REQUEST, { language });
const createCatalogueUser = (catalogueId, language, data) =>
  action(CREATE_CATALOGUE_USER.REQUEST, { catalogueId, language, data });

const createContactUser = (data) => action(CREATE_CONTACT_USER.REQUEST, { data });
const createCustomer = (data) => action(CREATE_CUSTOMER.REQUEST, { data });
const createCustomerBusiness = (data) => action(CREATE_CUSTOMER_BUSINESS.REQUEST, { data });
const createShippingDetails = (data) => action(CREATE_SHIPPING_DETAILS.REQUEST, { data });
const createBillingDetails = (data) => action(CREATE_BILLING_DETAILS.REQUEST, { data });
const createCustomerDelivery = (data) => action(CREATE_CUSTOMER_DELIVERY.REQUEST, { data });
const createCustomerPickUp = (data) => action(CREATE_CUSTOMER_PICKUP.REQUEST, { data });
const createCustomerRewards = (data) => action(CREATE_CUSTOMER_REWARDS.REQUEST, { data });

const getOpcoLocation = (language, userId) => action(OPCO_LOCATION.REQUEST, { language, userId });
const getSegments = (language) => action(SEGMENTS.REQUEST, { language });

const getLabelTransaltion = (language) => action(LABEL_TRANSLATION.REQUEST, { language });

export {
  loadCatalogues,
  getSegments,
  createCatalogueUser,
  createContactUser,
  createCustomer,
  createCustomerBusiness,
  createShippingDetails,
  createBillingDetails,
  createCustomerDelivery,
  createCustomerPickUp,
  createCustomerRewards,
  getOpcoLocation,
  getLabelTransaltion,
};
