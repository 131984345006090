import React from 'react';
import { Row, Col, Modal, Button, Card } from 'antd';
import { RightOutlined } from '@ant-design/icons';

import '../assets/css/Timeout.css';

const SYSCO_CANADA_TELEPHONE = process.env.REACT_APP_CONTACT_PHONE;

const Call = ({ setCallModalVisible, callModalVisible, handleTerminateOnBoarding, timeoutLabelTranslation }) => {
  const handleCallOnClick = () => {
    setCallModalVisible(false);
    handleTerminateOnBoarding();
  };

  return (
    <React.Fragment>
      <Modal
        title=""
        centered
        visible={callModalVisible}
        onOk={() => setCallModalVisible(false)}
        onCancel={() => setCallModalVisible(false)}
        width={400}
        footer={null}
        zIndex={10000000}
        closable={false}
        maskClosable={false}
        keyboard={false}
      >
        <Row className="">
          <Col span={24} className="col-leftside">
            <Col span={24}>
              <Card bordered={false} className="section-5-inner-3">
                <h1 className="help-header">
                  {timeoutLabelTranslation?.timeout_call} {SYSCO_CANADA_TELEPHONE.replace('tel:', '')}
                </h1>
                <Col span={24} className="call-icon-wrap">
                  <a className="section-5-inner-3-a" href={SYSCO_CANADA_TELEPHONE} onClick={() => handleCallOnClick()}>
                    <img src={require('../assets/Images/call.png')} className="call-image" />
                    <br />
                    {timeoutLabelTranslation?.timeout_call} <RightOutlined />
                  </a>
                </Col>
                <Col span={24} className="btn-timeout-wrap">
                  <Button
                    type="primary"
                    className="btn-primary-account btn-timeout"
                    onClick={() => setCallModalVisible(false)}
                  >
                    Cancel
                  </Button>
                </Col>
              </Card>
            </Col>
          </Col>
        </Row>
      </Modal>
    </React.Fragment>
  );
};

export default Call;
