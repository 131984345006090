import { Row, Col, Modal, Button } from 'antd';
import React from 'react';

import '../assets/css/Confimation.css';

const Confirmation = ({
  closeLabelTranslation,
  closeConfirmationVisible,
  setCloseConfirmationVisible,
  handleTerminateOnBoarding,
}) => {
  return (
    <Modal
      title=""
      centered
      visible={closeConfirmationVisible}
      onOk={() => setCloseConfirmationVisible(false)}
      closable={false}
      width={470}
      footer={null}
      zIndex={1000000}
    >
      <Row className="">
        <Col span={24} className="col-leftside">
          <Row className="confimation-box">
            <Col span={24} className="btn-confimation-wrap">
              <h1 className="confimation-heading">{closeLabelTranslation?.close_modal_header}</h1>
              <p>{closeLabelTranslation?.close_confirmation_text}</p>
            </Col>
            <Col span={12} className="btn-confimation-wrap">
              <Button
                type="primary"
                className="btn-primary-account btn-confimation"
                onClick={(e) => {
                  handleTerminateOnBoarding(e);
                }}
              >
                {closeLabelTranslation?.close_cancel_confirmation_text}
              </Button>
            </Col>
            <Col span={12} className="btn-confimation-wrap">
              <Button
                type="primary"
                className="btn-primary-account btn-confimation "
                onClick={() => {
                  setCloseConfirmationVisible(false);
                }}
              >
                {closeLabelTranslation?.close_continue_confirmation_text}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default Confirmation;
