import React from 'react';
import { Form, Input } from 'antd';

function InputField(props) {
  const {
    getFieldDecorator,
    value,
    setValue,
    maxLength,
    isRequired,
    labelName,
    errorMsg,
    patternFormat,
    patternErrorMsg,
    idValue,
    isNotEmail,
    isSize,
    isPatternRequried,
  } = props;

  return (
    <React.Fragment>
      <label className="catelog-lable">{labelName}</label>
      <Form.Item>
        {getFieldDecorator(`${idValue}`, {
          rules: [
            !isNotEmail
              ? {
                  type: 'email',
                  message: patternErrorMsg,
                }
              : [],
            isPatternRequried && isNotEmail
              ? {
                  pattern: patternFormat,
                  message: patternErrorMsg,
                }
              : [],
            {
              required: isRequired,
              message: errorMsg,
            },
          ],
        })(
          isSize ? (
            <Input
              id={idValue}
              className="input-first"
              placeholder={labelName}
              value={value}
              onChange={(e) => setValue(e.target.value)}
              maxLength={maxLength}
            />
          ) : (
            <Input
              size="large"
              id={idValue}
              placeholder={labelName}
              value={value}
              onChange={(e) => setValue(e.target.value)}
              maxLength={maxLength}
            />
          )
        )}
      </Form.Item>
    </React.Fragment>
  );
}

export default InputField;
