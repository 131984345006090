import React from 'react';
import { Row, Col, Modal, Card } from 'antd';

import '../assets/css/Timeout.css';

const Redirect = ({ setRedirectModalVisible, redirectModalVisible, redirectLabelTranslation }) => {
  return (
    <React.Fragment>
      <Modal
        title=""
        centered
        visible={redirectModalVisible}
        onOk={() => setRedirectModalVisible(false)}
        onCancel={() => setRedirectModalVisible(false)}
        width={400}
        footer={null}
        zIndex={10000000}
        maskClosable={false}
        keyboard={false}
      >
        <Row className="">
          <Col span={24} className="col-leftside">
            <Col span={24}>
              <Card bordered={false} className="section-5-inner-3">
                <h1 className="help-header">{redirectLabelTranslation?.leave_alert}</h1>
              </Card>
            </Col>
          </Col>
        </Row>
      </Modal>
    </React.Fragment>
  );
};

export default Redirect;
