import React from 'react';
import { Col } from 'antd';

import '../assets/css/Pickup.css';

const Pickup = ({ pickupLabelTranslation, resDistance, resOpcoName, resOpcoAddress }) => {
  return (
    <React.Fragment>
      <div>
        <Col xs={24} sm={24} md={12} lg={14} xl={14} className="col-leftside-pickup">
          <h3 className="pickup-subtext">{pickupLabelTranslation?.pickup_msg}</h3>

          <Col span={24} className="pickup-location">
            <Col span={4} className="km-box">
              <p className="km-number">{resDistance.split(' ')[0]}</p>
              <p className="km-text">{pickupLabelTranslation?.km_text}</p>
            </Col>
            <Col span={16}>
              <p className="delivery-sysco-cat">{resOpcoName}</p>
              <p className="delivery-sysco-address">{resOpcoAddress}</p>
            </Col>
          </Col>
          <Col span={24}>
            {' '}
            <p>{pickupLabelTranslation?.pickup_conf_msg}</p>
          </Col>
        </Col>
        <Col span="24" className="col-leftside-pickup pickup-footer" />
      </div>
    </React.Fragment>
  );
};

export default Pickup;
