/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Modal, Button, Steps } from 'antd';
import _ from 'lodash';
import { DownOutlined, RightOutlined, LeftOutlined, CheckOutlined } from '@ant-design/icons';
import Error from '../components/error';
import { getOpcoLocation, createCustomerDelivery, createCustomerPickUp } from '../actions';
import Delivery from './Delivery';
import Pickup from './Pickup';
import { LANGUAGE, LANG_CLASS_NAME, EMPTY_STRING } from '../constants/appConstants';

import '../assets/css/Delivery.css';

const LOADING_DEFAULT_LATENCY = process.env.REACT_APP_LOADING_DEFAULT_LATENCY;

const { Step } = Steps;

const ShippingType = ({
  shippingTypeLabelTranslation,
  language,
  setLanguage,
  setIsPickUp,
  setIsdDelivery,
  customerResUserId,
  isSameBillingAddress,
  billingHeaderVisible,
  isDelivery,
  isPickUp,
  customerUserId,
  isModalClosed,
  resOpcoId,
  shippingTypeModalVisible,
  setShippingTypeModalVisible,
  setOnboardingShippingModalVisible,
  setOnboardingBillingModalVisible,
  setOnboardingRewardsModalVisible,
  setCloseConfirmationVisible,
  setIsMonday,
  setIsTuesday,
  setIsWednesday,
  setIsThursday,
  setIsFriday,
  setSelectedNumberOfDays,
  setSelectedDeliveryDays,
  setIsAM,
  setIsPM,
  setIsNoPreference,
  setmaxDeliveryDays,
  selectedNumberOfDays,
  selectedDeliveryDays,
  maxDeliveryDays,
  isMonday,
  isTuesday,
  isWednesday,
  isThursday,
  isFriday,
  isPM,
  isAM,
  isNoPreference,
  resDistance,
  resOpcoName,
  resOpcoAddress,
  pickUpResError,
  deliveryResError,
  setShippinTypeLoadingState,
  shippinTypeLoadingState,
  deliveryWindow,
  setDeliveryWindow,
  purchaseOption,
  setPurchaseOption,
}) => {
  const dispatch = useDispatch();

  const deliveryResErrorRef = useRef(deliveryResError);

  const loadRewardsModalFromDelivery = _.debounce((deliveryResErrorRef) => {
    if (!deliveryResErrorRef.current) {
      setShippinTypeLoadingState(false);
      setShippingTypeModalVisible(false);
      setOnboardingRewardsModalVisible(true);
    }
  }, LOADING_DEFAULT_LATENCY);

  const handleCustomerDeliverySubmit = (e) => {
    e.preventDefault();
    if (
      customerUserId &&
      selectedNumberOfDays > 0 &&
      (isAM || isPM || isNoPreference) &&
      purchaseOption &&
      isDelivery
    ) {
      const validDeliveryDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
      const selectedDeliveryDaysArray = selectedDeliveryDays.reduce(
        (out, bool, index) => (bool ? out.concat(validDeliveryDays[index]) : out),
        []
      );

      const deliveryWindowType =
        deliveryWindow === undefined ? shippingTypeLabelTranslation?.delivery_no_preference : deliveryWindow;

      const deliveryData = {
        userId: customerUserId,
        isModelClosed: isModalClosed,
        weeklyPurchases: purchaseOption,
        deliveryDays: selectedDeliveryDaysArray,
        deliveryWindow: deliveryWindowType,
        lang: language,
      };
      setShippinTypeLoadingState(true);
      dispatch(createCustomerDelivery(deliveryData));
      loadRewardsModalFromDelivery(deliveryResErrorRef);
    }
  };

  const handleDeliveryBack = (e) => {
    e.preventDefault();
    setShippingTypeModalVisible(false);
    if (isSameBillingAddress) {
      setOnboardingShippingModalVisible(true);
    } else {
      setOnboardingBillingModalVisible(true);
    }
  };

  const pickUpResErrorRef = useRef(pickUpResError);

  const loadRewardsModalFromPickUp = _.debounce((pickUpResErrorRef) => {
    if (!pickUpResErrorRef.current) {
      setShippinTypeLoadingState(false);
      setShippingTypeModalVisible(false);
      setOnboardingRewardsModalVisible(true);
    }
  }, LOADING_DEFAULT_LATENCY);

  const handleCustomerPickupSubmit = (e) => {
    e.preventDefault();
    if (customerUserId && resOpcoAddress && resOpcoId && resOpcoName && resDistance) {
      const pickupData = {
        userId: customerUserId,
        isModelClosed: isModalClosed,
        opcoId: resOpcoId,
        lang: language,
      };
      setShippinTypeLoadingState(true);
      dispatch(createCustomerPickUp(pickupData));
      loadRewardsModalFromPickUp(pickUpResErrorRef);
    }
  };

  const handleDeliveryType = (event) => {
    event.preventDefault();
    setIsdDelivery(true);
    setIsPickUp(false);
  };

  const handlePickUpType = (event) => {
    event.preventDefault();
    setIsPickUp(true);
    setIsdDelivery(false);
    dispatch(getOpcoLocation(language, customerResUserId));
  };

  useEffect(() => {
    pickUpResErrorRef.current = pickUpResError;
  }, [pickUpResError]);

  useEffect(() => {
    deliveryResErrorRef.current = deliveryResError;
  }, [deliveryResError]);

  return (
    <Modal
      className="contactus-model"
      title=""
      centered
      visible={shippingTypeModalVisible}
      onOk={() => setShippingTypeModalVisible(false)}
      onCancel={() => setCloseConfirmationVisible(true)}
      width={1200}
      footer={null}
      maskClosable={false}
      keyboard={false}
    >
      <Row className="model-body order-model-body">
        <Col xs={24} sm={24} md={8} lg={8} xl={6} className="model-header">
          <p className="newcustomer-heading">{shippingTypeLabelTranslation?.steps_become_a_customer_default}</p>
        </Col>
        <Col xs={15} sm={15} md={12} lg={12} xl={14} className="model-header ncob-steps">
          <Steps size="small" current={isSameBillingAddress ? 3 : 4}>
            <Step title={shippingTypeLabelTranslation?.steps_about_you} />
            <Step title={shippingTypeLabelTranslation?.steps_your_business} />
            <Step title={shippingTypeLabelTranslation?.steps_shipping_address} />
            {billingHeaderVisible ? <Step title={shippingTypeLabelTranslation?.steps_your_billing_address} /> : null}
            <Step title={shippingTypeLabelTranslation?.steps_shipping} />
            <Step title={shippingTypeLabelTranslation?.steps_rewards_finish} />
          </Steps>
        </Col>
        <Col xs={9} sm={9} md={4} lg={4} xl={4} className="model-header">
          <span
            className={
              billingHeaderVisible
                ? 'Language-selection-newcustomer Language-selection-newcustomer-credit Language-selection-newcustomer-billing'
                : 'Language-selection-newcustomer Language-selection-newcustomer-credit Language-selection-newcustomer-without-billing'
            }
          >
            <a
              href="#!"
              className={language === LANGUAGE.ENGLISH ? LANG_CLASS_NAME : EMPTY_STRING}
              onClick={() => {
                setLanguage(LANGUAGE.ENGLISH);
              }}
            >
              EN
            </a>
            <span> I </span>
            <a
              href="#!"
              className={language === LANGUAGE.FRENCH ? LANG_CLASS_NAME : EMPTY_STRING}
              onClick={() => {
                setLanguage(LANGUAGE.FRENCH);
              }}
            >
              FR
            </a>
          </span>
        </Col>
        {!(pickUpResError || deliveryResError) ? (
          <React.Fragment>
            <Col xs={24} sm={24} md={14} lg={14} xl={14} className="col-leftside-pickup">
              <h3>{shippingTypeLabelTranslation?.shipping_type_modal_text}</h3>
              <Col span={12}>
                <div
                  className={
                    isDelivery ? 'your-order-slot receive-order-delivery pickup-option1-selected' : 'your-order-slot'
                  }
                  onClick={(e) => handleDeliveryType(e)}
                >
                  <CheckOutlined className="order-now-delivery" />
                  {shippingTypeLabelTranslation?.shipping_type_delivery_header}
                </div>
              </Col>
              <Col span={12}>
                <div
                  className={
                    isPickUp ? 'your-order-slot receive-order-delivery pickup-option1-selected' : 'your-order-slot'
                  }
                  onClick={(e) => handlePickUpType(e)}
                >
                  <CheckOutlined className="order-now-delivery" />
                  {shippingTypeLabelTranslation?.shipping_type_pickup_header}
                </div>
              </Col>
            </Col>
            {isDelivery ? (
              <Delivery
                deliveryLabelTranslation={shippingTypeLabelTranslation}
                setIsMonday={setIsMonday}
                setIsTuesday={setIsTuesday}
                setIsWednesday={setIsWednesday}
                setIsThursday={setIsThursday}
                setIsFriday={setIsFriday}
                setSelectedNumberOfDays={setSelectedNumberOfDays}
                setSelectedDeliveryDays={setSelectedDeliveryDays}
                setIsAM={setIsAM}
                setIsPM={setIsPM}
                setIsNoPreference={setIsNoPreference}
                setDeliveryWindow={setDeliveryWindow}
                setmaxDeliveryDays={setmaxDeliveryDays}
                selectedNumberOfDays={selectedNumberOfDays}
                selectedDeliveryDays={selectedDeliveryDays}
                maxDeliveryDays={maxDeliveryDays}
                isMonday={isMonday}
                isTuesday={isTuesday}
                isWednesday={isWednesday}
                isThursday={isThursday}
                isFriday={isFriday}
                isPM={isPM}
                isAM={isAM}
                isNoPreference={isNoPreference}
                purchaseOption={purchaseOption}
                setPurchaseOption={setPurchaseOption}
              />
            ) : isPickUp ? (
              <Pickup
                pickupLabelTranslation={shippingTypeLabelTranslation}
                resDistance={resDistance}
                resOpcoName={resOpcoName}
                resOpcoAddress={resOpcoAddress}
              />
            ) : null}
            <Col span="24" className="col-leftside-pickup delivery-footer">
              <Button
                disabled={shippinTypeLoadingState}
                className="form-btn-link btn-back"
                type="primary"
                onClick={(e) => handleDeliveryBack(e)}
              >
                <LeftOutlined />
                {shippingTypeLabelTranslation?.delivery_back}
              </Button>
              {isDelivery && selectedNumberOfDays > 0 && (isAM || isPM || isNoPreference) ? (
                <Button
                  loading={shippinTypeLoadingState}
                  className="catelog-btn"
                  type="primary"
                  onClick={(e) => handleCustomerDeliverySubmit(e)}
                >
                  {shippingTypeLabelTranslation?.delivery_continue}
                  <RightOutlined />
                </Button>
              ) : isPickUp ? (
                <Button
                  loading={shippinTypeLoadingState}
                  className="catelog-btn"
                  type="primary"
                  onClick={(e) => handleCustomerPickupSubmit(e)}
                >
                  {shippingTypeLabelTranslation?.pickup_continue}

                  <RightOutlined />
                </Button>
              ) : (
                <Button className="catelog-btn-disabled" type="primary" disabled>
                  {shippingTypeLabelTranslation?.pickup_continue}
                  <RightOutlined />
                </Button>
              )}
            </Col>
          </React.Fragment>
        ) : (
          <Error errorLabelTranslation={shippingTypeLabelTranslation} />
        )}
      </Row>
    </Modal>
  );
};

export default ShippingType;
