import React from 'react';
import { IntercomProvider } from 'react-use-intercom';
import { Provider } from 'react-redux';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';

import store from './store/index';

import Home from './pages/Home';
import OrderNow from './pages/OrderNow';
import './assets/css/App.css';
import './assets/css/Home.css';

function App() {
  const { REACT_APP_INTERCOM_APP_ID } = process.env;
  return (
    <IntercomProvider appId={REACT_APP_INTERCOM_APP_ID} autoBoot>
      <Provider store={store}>
        <Router>
          <Switch>
            <Route exact path="/ordernow" component={OrderNow} />
            <Route exact path="/home" component={Home} />
            <Route exact path="*" component={Home} />
          </Switch>
        </Router>
      </Provider>
    </IntercomProvider>
  );
}

export default App;
