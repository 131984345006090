import React from 'react';
import { useIntercom } from 'react-use-intercom';
import { Row, Col, Modal, Button, Card } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import '../assets/css/Timeout.css';

const SYSCO_CANADA_TELEPHONE = process.env.REACT_APP_CONTACT_PHONE;

const Timeout = ({
  timeoutLabelTranslation,
  setTimeoutModalVisible,
  timeoutModalVisible,
  handleTerminateOnBoarding,
  setCallModalVisible,
}) => {
  const { show: showIntercom } = useIntercom();

  const handleTimeOutIntercom = () => {
    showIntercom();
    handleTimeOutOnClick();
  };

  const handleTimeOutOnClick = () => {
    setTimeoutModalVisible(false);
    handleTerminateOnBoarding();
  };

  const handleCallOnClick = () => {
    setTimeoutModalVisible(false);
    setCallModalVisible(true);
  };

  return (
    <>
      <Modal
        title=""
        centered
        visible={timeoutModalVisible}
        onOk={() => setTimeoutModalVisible(false)}
        onCancel={() => setTimeoutModalVisible(false)}
        width={400}
        footer={null}
        zIndex={1000000}
        closable={false}
        maskClosable={false}
        keyboard={false}
      >
        <Row className="">
          <Col span={24} className="col-leftside">
            <Col span={24}>
              <Card bordered={false} className="section-5-inner-3">
                <h1 className="help-header help-header-french">{timeoutLabelTranslation?.timeout_text}</h1>

                <Col span={3} />
                <Col span={9}>
                  <a className="section-5-inner-3-a" onClick={() => handleCallOnClick()}>
                    <img src={require('../assets/Images/call.png')} />
                    <br />
                    {timeoutLabelTranslation?.timeout_call} <RightOutlined />
                  </a>
                </Col>
                <Col span={9}>
                  <a className="section-5-inner-3-a" onClick={() => handleTimeOutIntercom()}>
                    <img src={require('../assets/Images/chat.png')} />
                    <br />
                    {timeoutLabelTranslation?.timeout_chat} <RightOutlined />
                  </a>
                </Col>
                <Col span={3} />
                <Col span={24} className="btn-timeout-wrap">
                  <Button
                    type="primary"
                    className="btn-primary-account btn-timeout btn-timeout-model"
                    onClick={() => setTimeoutModalVisible(false)}
                  >
                    {timeoutLabelTranslation?.timeout_okay}
                  </Button>
                </Col>
              </Card>
            </Col>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default Timeout;
