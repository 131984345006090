import React from 'react';
import { Row, Col, Modal } from 'antd';

import '../assets/css/Catalogues.css';

const CatalogueThankYou = ({
  catalogueThankYouLabelTranslation,
  catalogueDownloadThankYouModalVisible,
  setCatalogueDownloadThankYouModalVisible,
  catalogueImageBaseUrl,
  catalogueToShow,
}) => {
  return (
    <Modal
      className="contactus-model"
      title=""
      centered
      visible={catalogueDownloadThankYouModalVisible}
      onOk={() => setCatalogueDownloadThankYouModalVisible(false)}
      onCancel={() => setCatalogueDownloadThankYouModalVisible(false)}
      width={1000}
      footer={null}
      maskClosable={false}
      keyboard={false}
    >
      <Row className="">
        <Col xs={24} sm={24} md={24} lg={9} xl={9} span={9} className="col-leftside-catalogue ">
          <img
            className="leftside-image"
            alt={`${catalogueDownloadThankYouModalVisible}`}
            src={`${catalogueImageBaseUrl}${catalogueToShow?.imageUrl}`}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={15} xl={15} span={15} className="col-rightside">
          <h1 className="Thank-you">{catalogueThankYouLabelTranslation?.catalogue_thankyou_msg}</h1>
        </Col>
      </Row>
    </Modal>
  );
};

export default CatalogueThankYou;
