import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Modal, Button, Form, Select, Checkbox, Steps } from 'antd';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import { REWARDS_OPTION, EMPTY_STRING, LANGUAGE, LANG_CLASS_NAME, GOOGLE_ANALYTICS } from '../constants/appConstants';
import { createCustomerRewards } from '../actions';
import TermsAndConditions from './TermsAndConditions';
import Error from '../components/error';

import '../assets/css/RewardsAndFinish.css';

const GOOGLE_ANALYTICS_KEY = process.env.REACT_APP_GOOGLE_ANALYTICS_KEY;
const LINKEDIN_PARTNER_ID = process.env.REACT_APP_LINKEDIN_PARTNER_ID;
const LINKEDIN_CONVERSION_ID = process.env.REACT_APP_LINKEDIN_CONVERSION_ID;
const FACEBOOK_KEY = process.env.REACT_APP_FACEBOOK_KEY;
const FACEBOOK_TRACK = process.env.REACT_APP_FACEBOOK_TRACK;
const FACEBOOK_REGISTRATION_KEY = process.env.REACT_APP_FACEBOOK_REGISTRATION_KEY;
const FACEBOOK_REGISTRATION_TRACK = process.env.REACT_APP_FACEBOOK_REGISTRATION_TRACK;

const { Step } = Steps;

const RewardsAndFinish = ({
  rewardAndFinishLabelTranslation,
  language,
  setLanguage,
  setRewardsOptions,
  setOnboardingRewardsModalVisible,
  setShippingTypeModalVisible,
  agreeToSyscoRewards,
  agreeToSyscoTerms,
  rewardsOptions,
  customerUserId,
  isModalClosed,
  setOnboardingCompletedModalVisible,
  clearOnBoardingFormData,
  onboardingRewardsModalVisible,
  setCloseConfirmationVisible,
  rewardOptionActionNo,
  billingHeaderVisible,
  setAgreeToSyscoRewards,
  setAgreeToSyscoTerms,
  setTermsAndConditionsVisible,
  termsAndConditionsVisible,
  setSyscoRewardsTermsAndConditionsVisible,
  syscoRewardsTermsAndConditionsVisible,
  rewardsResError,
  setRewardsLoadingState,
  rewardsLoadingState,
  getFieldDecorator,
  rewardsFetching,
  rewardsIsSuccess,
  setIsRewardsOnboardingForm,
  isRewardsOnboardingForm,
  form,
  userId,
  resOpcoId,
  isHomePage,
}) => {
  const dispatch = useDispatch();

  const [rewardsOptionsList, setRewardsOptionsList] = useState(REWARDS_OPTION.EN);

  const customerPortalPath = '/customer-portal/';
  const customerPortalOrderNowPath = 'order-now/';

  useEffect(() => {
    if (language === LANGUAGE.FRENCH) {
      setRewardsOptionsList(REWARDS_OPTION.FR);
    } else {
      setRewardsOptionsList(REWARDS_OPTION.EN);
    }
  }, [language]);

  const handleRewardsOptionOnChange = (option) => {
    setRewardsOptions(option.trim());
  };

  const handleRewardsDetailsBackButton = () => {
    setOnboardingRewardsModalVisible(false);
    setShippingTypeModalVisible(true);
  };

  const validateRewardsFieldValue = (fieldName) => {
    form.validateFields([fieldName], (errors) => {
      if (errors) {
        form.resetFields([fieldName]);
      }
    });
  };

  const clearAllRewardsFields = () => {
    validateRewardsFieldValue('termsConfirmation');
    validateRewardsFieldValue('rewardsTermsConfirmation');
  };

  const handleRewardsDetailsSubmit = (e) => {
    e.preventDefault();
    form.validateFields(['termsConfirmation', 'rewardsTermsConfirmation'], (err) => {
      if (!err) {
        if (agreeToSyscoRewards && agreeToSyscoTerms) {
          const rewardsDetailsData = {
            userId: customerUserId,
            isModelClosed: isModalClosed,
            hasSyscoRewards: agreeToSyscoRewards,
            heardAboutUsFrom: rewardsOptions,
            lang: language,
          };
          setIsRewardsOnboardingForm(true);
          dispatch(createCustomerRewards(rewardsDetailsData));
          setRewardsLoadingState(true);
        }
      }
    });
  };

  const handleSyscoRewardsTermsAndConditions = (e) => {
    e.preventDefault();
    setSyscoRewardsTermsAndConditionsVisible(true);
    setTermsAndConditionsVisible(true);
  };

  const googleAnalytics = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    const path = isHomePage ? customerPortalPath : customerPortalPath + customerPortalOrderNowPath;
    gtag('js', new Date());
    gtag('config', GOOGLE_ANALYTICS_KEY, {
      page_path: path,
      cookie_domain: 'none',
    });

    gtag('event', GOOGLE_ANALYTICS.EVENT, {
      event_category: GOOGLE_ANALYTICS.EVENT_CATEGORY,
      event_label: `${resOpcoId}-${userId}`,
      value: GOOGLE_ANALYTICS.VALUE,
    });
  };

  const googleAnalyticsSignup = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    const path = isHomePage ? customerPortalPath : customerPortalPath + customerPortalOrderNowPath;
    gtag('js', new Date());
    gtag('config', GOOGLE_ANALYTICS_KEY, {
      page_path: `${path}confirmation?customerNo=${userId}&&opcoId=${resOpcoId}`,
      cookie_domain: 'none',
    });

    gtag('event', GOOGLE_ANALYTICS.CUSTOMER_EVENT, {
      event_category: GOOGLE_ANALYTICS.CUSTOMER_EVENT_CATEGORY,
    });

    const linkedInSrc = `https://px.ads.linkedin.com/collect/?pid=${LINKEDIN_PARTNER_ID}&conversionId=${LINKEDIN_CONVERSION_ID}&fmt=gif`;
    const facebookSrc = `https://www.facebook.com/tr?id=${FACEBOOK_KEY}&ev=${FACEBOOK_TRACK}&noscript=1`;
    const facebookRegistrationSrc = `https://www.facebook.com/tr?id=${FACEBOOK_REGISTRATION_KEY}&ev=${FACEBOOK_REGISTRATION_TRACK}&noscript=1`;

    window.fbq('init', FACEBOOK_KEY);
    window.fbq('track', FACEBOOK_TRACK);

    window.fbq('init', FACEBOOK_REGISTRATION_KEY);
    window.fbq('track', FACEBOOK_REGISTRATION_TRACK);

    return () => (
      <React.Fragment>
        <img height="1" width="1" style="display: none;" alt="" src={linkedInSrc} />
        <img height="1" width="1" alt="" style="display: none;" src={facebookSrc} />
        <img height="1" width="1" alt="" style="display: none;" src={facebookRegistrationSrc} />
      </React.Fragment>
    );
  };

  useEffect(() => {
    if (rewardsIsSuccess && !rewardsFetching && isRewardsOnboardingForm) {
      googleAnalytics();
      setRewardsLoadingState(false);
      setOnboardingRewardsModalVisible(false);
      setOnboardingCompletedModalVisible(true);
      googleAnalyticsSignup();
      clearOnBoardingFormData();
    }
  }, [rewardsIsSuccess, rewardsFetching, isRewardsOnboardingForm]);

  return (
    <React.Fragment>
      <Modal
        className="contactus-model"
        title=""
        centered
        visible={onboardingRewardsModalVisible}
        onOk={() => setOnboardingRewardsModalVisible(false)}
        onCancel={() => setCloseConfirmationVisible(true)}
        width={1200}
        footer={null}
        maskClosable={false}
        keyboard={false}
        forceRender
      >
        <Row className="model-body">
          <Col xs={24} sm={24} md={8} lg={6} xl={6} className="model-header">
            <p className="newcustomer-heading">{rewardAndFinishLabelTranslation?.steps_become_a_customer_default}</p>
          </Col>
          <Col xs={15} sm={15} md={12} lg={14} xl={14} className="model-header ncob-steps">
            <Steps size="small" current={rewardOptionActionNo}>
              <Step title={rewardAndFinishLabelTranslation?.steps_about_you} />
              <Step title={rewardAndFinishLabelTranslation?.steps_your_business} />
              <Step title={rewardAndFinishLabelTranslation?.steps_shipping_address} />
              {billingHeaderVisible ? (
                <Step title={rewardAndFinishLabelTranslation?.steps_your_billing_address} />
              ) : null}
              <Step title={rewardAndFinishLabelTranslation?.steps_shipping} />
              <Step title={rewardAndFinishLabelTranslation?.steps_rewards_finish} />
            </Steps>
          </Col>
          <Col xs={9} sm={9} md={4} lg={4} xl={4} className="model-header">
            <span
              className={
                billingHeaderVisible
                  ? 'Language-selection-newcustomer Language-selection-newcustomer-billing'
                  : 'Language-selection-newcustomer Language-selection-newcustomer-without-billing'
              }
            >
              <a
                href="#!"
                className={language === LANGUAGE.ENGLISH ? LANG_CLASS_NAME : EMPTY_STRING}
                onClick={() => {
                  setLanguage(LANGUAGE.ENGLISH);
                  clearAllRewardsFields();
                }}
              >
                EN
              </a>
              <span> I </span>
              <a
                href="#!"
                className={language === LANGUAGE.FRENCH ? LANG_CLASS_NAME : EMPTY_STRING}
                onClick={() => {
                  setLanguage(LANGUAGE.FRENCH);
                  clearAllRewardsFields();
                }}
              >
                FR
              </a>
            </span>
          </Col>
          {!rewardsResError ? (
            <Col xs={24} sm={24} md={24} lg={20} xl={20} className="col-newcustomer">
              <Col span={24} className="col-leftside-aboutyou-model">
                <Col span={24} className="">
                  <h3>{rewardAndFinishLabelTranslation?.rewards_finish_join_syco_msg}</h3>
                </Col>
                <Col xs={24} sm={8} md={7} lg={7} xl={7} className="rewards-item">
                  <img src={require('../assets/Images/collect.png')} />
                  <p className="rewards-and-receive">{rewardAndFinishLabelTranslation?.rewards_finish_collect}</p>
                  <p>{rewardAndFinishLabelTranslation?.rewards_finish_collect_msg}</p>
                </Col>
                <Col xs={24} sm={8} md={7} lg={7} xl={7} className="rewards-item">
                  <img src={require('../assets/Images/combine.png')} />
                  <p className="rewards-and-receive">{rewardAndFinishLabelTranslation?.rewards_finish_combine}</p>
                  <p>{rewardAndFinishLabelTranslation?.rewards_finish_combine_msg}</p>
                </Col>
                <Col xs={24} sm={8} md={7} lg={7} xl={7} className="rewards-item">
                  <img src={require('../assets/Images/claim.png')} />
                  <p className="rewards-and-receive">{rewardAndFinishLabelTranslation?.rewards_finish_claim}</p>
                  <p>{rewardAndFinishLabelTranslation?.rewards_finish_claim_msg}</p>
                </Col>
                <Col xs={24} sm={24} md={24} lg={21} xl={22} className="">
                  <h3>{rewardAndFinishLabelTranslation?.rewards_finish_sign_up_rewards}</h3>
                </Col>
                <Col xs={24} sm={24} md={22} lg={21} xl={22} className="industry-text">
                  <Form.Item>
                    {getFieldDecorator('rewardsTermsConfirmation', {
                      rules: [
                        {
                          required: true,
                          transform: (value) => value || undefined, // Those two lines
                          type: 'boolean',
                          message: rewardAndFinishLabelTranslation?.error_rewards_sysco_rewards_terms_checkbox,
                        },
                      ],
                    })(
                      <Checkbox
                        checked={agreeToSyscoRewards}
                        onChange={(e) => setAgreeToSyscoRewards(e.target.checked)}
                      >
                        {rewardAndFinishLabelTranslation?.rewards_finish_agree_msg}
                        <a onClick={(e) => handleSyscoRewardsTermsAndConditions(e)}>
                          {rewardAndFinishLabelTranslation?.rewards_terms_and_cond_rewards}
                        </a>
                      </Checkbox>
                    )}
                  </Form.Item>
                  <hr className="menu-divider-rewards"></hr>
                </Col>
                <Col xs={24} sm={24} md={22} lg={21} xl={22} className="industry-text hear-aboutus">
                  <label className="catelog-lable">
                    {rewardAndFinishLabelTranslation?.rewards_finish_hear_us_options}
                  </label>
                  {getFieldDecorator(
                    'RewardsSelVal',
                    {}
                  )(
                    <Select
                      className="form-select"
                      placeholder={rewardAndFinishLabelTranslation?.rewards_select_an_option}
                      onChange={(value) => handleRewardsOptionOnChange(value)}
                    >
                      {rewardsOptionsList?.map((option) => (
                        <Select.Option key={option} value={option}>
                          {option}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Col>
                <Col xs={24} sm={24} md={22} lg={21} xl={22} className="industry-text">
                  <hr className="menu-divider-rewards"></hr>
                </Col>

                <Col xs={24} sm={24} md={22} lg={21} xl={22} className="industry-text">
                  <Form.Item>
                    {getFieldDecorator('termsConfirmation', {
                      rules: [
                        {
                          required: true,
                          transform: (value) => value || undefined, // Those two lines
                          type: 'boolean',
                          message: rewardAndFinishLabelTranslation?.error_rewards_terms_checkbox,
                        },
                      ],
                    })(
                      <Checkbox checked={agreeToSyscoTerms} onChange={(e) => setAgreeToSyscoTerms(e.target.checked)}>
                        {rewardAndFinishLabelTranslation?.rewards_finish_agree_msg_terms}
                        <a onClick={() => setTermsAndConditionsVisible(true)}>
                          {rewardAndFinishLabelTranslation?.rewards_finish_terms_cond}
                        </a>
                      </Checkbox>
                    )}
                  </Form.Item>
                </Col>
                <Col span={24} className="">
                  <Button
                    disabled={rewardsLoadingState}
                    className="form-btn-link btn-back"
                    type="primary"
                    onClick={() => handleRewardsDetailsBackButton()}
                  >
                    {' '}
                    <LeftOutlined />
                    {rewardAndFinishLabelTranslation?.rewards_finish_back}
                  </Button>
                  <span className="linkSeparator"></span>
                  <Button
                    loading={rewardsLoadingState}
                    className="catelog-btn"
                    type="primary"
                    onClick={(e) => handleRewardsDetailsSubmit(e)}
                  >
                    {rewardAndFinishLabelTranslation?.rewards_finish_continue}
                    <RightOutlined />
                  </Button>
                </Col>
              </Col>
            </Col>
          ) : (
            <Error errorLabelTranslation={rewardAndFinishLabelTranslation} />
          )}
        </Row>
      </Modal>
      <TermsAndConditions
        rewardAndFinishLabelTranslation={rewardAndFinishLabelTranslation}
        language={language}
        setLanguage={setLanguage}
        setTermsAndConditionsVisible={setTermsAndConditionsVisible}
        termsAndConditionsVisible={termsAndConditionsVisible}
        setSyscoRewardsTermsAndConditionsVisible={setSyscoRewardsTermsAndConditionsVisible}
        syscoRewardsTermsAndConditionsVisible={syscoRewardsTermsAndConditionsVisible}
      />
    </React.Fragment>
  );
};

export default RewardsAndFinish;
