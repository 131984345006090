/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Col, Select } from 'antd';

import {
  PURCHASE_OPTIONS,
  DELIVERY_WINDOW_NO_PREFERENCE,
  DELIVERY_WINDOW_AM,
  DELIVERY_WINDOW_PM,
} from '../constants/appConstants';

import '../assets/css/Delivery.css';

const Delivery = ({
  deliveryLabelTranslation,
  setIsMonday,
  setIsTuesday,
  setIsWednesday,
  setIsThursday,
  setIsFriday,
  setSelectedNumberOfDays,
  setSelectedDeliveryDays,
  setIsAM,
  setIsPM,
  setIsNoPreference,
  setDeliveryWindow,
  setmaxDeliveryDays,
  selectedNumberOfDays,
  selectedDeliveryDays,
  maxDeliveryDays,
  isMonday,
  isTuesday,
  isWednesday,
  isThursday,
  isFriday,
  isPM,
  isAM,
  isNoPreference,
  purchaseOption,
  setPurchaseOption,
}) => {
  const handlePurchaseOption = (value) => {
    setPurchaseOption(value);
    setIsMonday(false);
    setIsTuesday(false);
    setIsWednesday(false);
    setIsThursday(false);
    setIsFriday(false);
    setSelectedNumberOfDays(0);
    setSelectedDeliveryDays([false, false, false, false, false]);
  };

  const handleIsAM = (event) => {
    event.preventDefault();
    setIsAM(true);
    setIsPM(false);
    setIsNoPreference(false);
    setDeliveryWindow(DELIVERY_WINDOW_AM);
  };

  const handleIsPM = (event) => {
    event.preventDefault();
    setIsAM(false);
    setIsPM(true);
    setIsNoPreference(false);
    setDeliveryWindow(DELIVERY_WINDOW_PM);
  };

  const handleIsNoPreference = (event) => {
    event.preventDefault();
    setIsAM(false);
    setIsPM(false);
    setIsNoPreference(true);
    setDeliveryWindow(DELIVERY_WINDOW_NO_PREFERENCE);
  };

  const handleIsMonday = (e, index) => {
    if (!isMonday && selectedNumberOfDays < maxDeliveryDays) {
      setIsMonday(true);
      selectedDeliveryDays[index] = true;
      setSelectedDeliveryDays(selectedDeliveryDays);
      setSelectedNumberOfDays(selectedDeliveryDays.filter(Boolean).length);
    } else {
      setIsMonday(false);
      selectedDeliveryDays[index] = false;
      setSelectedDeliveryDays(selectedDeliveryDays);
      setSelectedNumberOfDays(selectedDeliveryDays.filter(Boolean).length);
    }
  };

  const handleIsTuesday = (e, index) => {
    if (!isTuesday && selectedNumberOfDays < maxDeliveryDays) {
      setIsTuesday(true);
      selectedDeliveryDays[index] = true;
      setSelectedDeliveryDays(selectedDeliveryDays);
      setSelectedNumberOfDays(selectedDeliveryDays.filter(Boolean).length);
    } else {
      setIsTuesday(false);
      selectedDeliveryDays[index] = false;
      setSelectedDeliveryDays(selectedDeliveryDays);
      setSelectedNumberOfDays(selectedDeliveryDays.filter(Boolean).length);
    }
  };

  const handleIsWednesday = (e, index) => {
    e.preventDefault();
    if (!isWednesday && selectedNumberOfDays < maxDeliveryDays) {
      setIsWednesday(true);
      selectedDeliveryDays[index] = true;
      setSelectedDeliveryDays(selectedDeliveryDays);
      setSelectedNumberOfDays(selectedDeliveryDays.filter(Boolean).length);
    } else {
      setIsWednesday(false);
      selectedDeliveryDays[index] = false;
      setSelectedDeliveryDays(selectedDeliveryDays);
      setSelectedNumberOfDays(selectedDeliveryDays.filter(Boolean).length);
    }
  };

  const handleIsThursday = (e, index) => {
    e.preventDefault();
    if (!isThursday && selectedNumberOfDays < maxDeliveryDays) {
      setIsThursday(true);
      selectedDeliveryDays[index] = true;
      setSelectedDeliveryDays(selectedDeliveryDays);
      setSelectedNumberOfDays(selectedDeliveryDays.filter(Boolean).length);
    } else {
      setIsThursday(false);
      selectedDeliveryDays[index] = false;
      setSelectedDeliveryDays(selectedDeliveryDays);
      setSelectedNumberOfDays(selectedDeliveryDays.filter(Boolean).length);
    }
  };

  const handleIsFriday = (e, index) => {
    e.preventDefault();
    if (!isFriday && selectedNumberOfDays < maxDeliveryDays) {
      setIsFriday(true);
      selectedDeliveryDays[index] = true;
      setSelectedDeliveryDays(selectedDeliveryDays);
      setSelectedNumberOfDays(selectedDeliveryDays.filter(Boolean).length);
    } else {
      setIsFriday(false);
      selectedDeliveryDays[index] = false;
      setSelectedDeliveryDays(selectedDeliveryDays);
      setSelectedNumberOfDays(selectedDeliveryDays.filter(Boolean).length);
    }
  };
  return (
    <React.Fragment>
      <div>
        <Col xs={24} sm={24} md={12} lg={14} xl={14} className="col-leftside-pickup">
          <Col span={24} className="industry-text estimate-dropdown">
            <label className="estimate-dropdown-lable">{deliveryLabelTranslation.delivery_weekly_purchases}</label>
            <Select
              className="form-select"
              defaultValue={purchaseOption}
              onChange={(value) => handlePurchaseOption(value)}
            >
              <Select.Option value={PURCHASE_OPTIONS.OPTION_1} onClick={() => setmaxDeliveryDays(1)}>
                &#60;
                {PURCHASE_OPTIONS.OPTION_1}
              </Select.Option>
              <Select.Option value={PURCHASE_OPTIONS.OPTION_2} onClick={() => setmaxDeliveryDays(2)}>
                {PURCHASE_OPTIONS.OPTION_2}
              </Select.Option>
              <Select.Option value={PURCHASE_OPTIONS.OPTION_3} onClick={() => setmaxDeliveryDays(3)}>
                {PURCHASE_OPTIONS.OPTION_3}
              </Select.Option>
              <Select.Option value={PURCHASE_OPTIONS.OPTION_4} onClick={() => setmaxDeliveryDays(4)}>
                &#62;
                {PURCHASE_OPTIONS.OPTION_4}
              </Select.Option>
            </Select>
          </Col>
        </Col>
        <Col xs={24} sm={24} md={14} lg={14} xl={14} className="col-leftside-pickup">
          <h3>
            {deliveryLabelTranslation.delivery_choose_msg}
            {maxDeliveryDays}
            {deliveryLabelTranslation.delivery_preferred_day_msg}
          </h3>
          <p>{deliveryLabelTranslation.delivery_preference_best_msg}</p>
          <Col span={5}>
            <div
              className={isMonday ? 'industry-item-delivery day-selected ' : 'industry-item-delivery '}
              onClick={(e) => handleIsMonday(e, 0)}
            >
              {deliveryLabelTranslation.delivery_monday}
            </div>
          </Col>
          <Col span={5}>
            <div
              className={isTuesday ? 'industry-item-delivery day-selected ' : 'industry-item-delivery '}
              onClick={(e) => handleIsTuesday(e, 1)}
            >
              {deliveryLabelTranslation.delivery_tuesday}
            </div>
          </Col>
          <Col span={5}>
            <div
              className={isWednesday ? 'industry-item-delivery day-selected ' : 'industry-item-delivery '}
              onClick={(e) => handleIsWednesday(e, 2)}
            >
              {deliveryLabelTranslation.delivery_wednesday}
            </div>
          </Col>
          <Col span={5}>
            <div
              className={isThursday ? 'industry-item-delivery day-selected ' : 'industry-item-delivery '}
              onClick={(e) => handleIsThursday(e, 3)}
            >
              {deliveryLabelTranslation.delivery_thursday}
            </div>
          </Col>
          <Col span={4}>
            <div
              className={isFriday ? 'industry-item-delivery day-selected ' : 'industry-item-delivery '}
              onClick={(e) => handleIsFriday(e, 4)}
            >
              {deliveryLabelTranslation.delivery_friday}
            </div>
          </Col>
        </Col>
        <Col xs={24} sm={24} md={14} lg={14} xl={14} className="col-leftside-pickup">
          <h3>{deliveryLabelTranslation.delivery_type_selection_msg}</h3>

          <Col span={5}>
            <div
              className={isAM ? 'industry-item-delivery day-selected  ' : 'industry-item-delivery '}
              onClick={(event) => handleIsAM(event)}
            >
              {deliveryLabelTranslation.delivery_am_preference}
            </div>
          </Col>
          <Col span={5}>
            <div
              className={isPM ? 'industry-item-delivery day-selected  ' : 'industry-item-delivery '}
              onClick={(event) => handleIsPM(event)}
            >
              {' '}
              {deliveryLabelTranslation.delivery_pm_preference}
            </div>
          </Col>
          <Col xs={12} sm={12} md={8} lg={8} xl={8}>
            <div
              className={isNoPreference ? 'industry-item-delivery day-selected  ' : 'industry-item-delivery '}
              onClick={(event) => handleIsNoPreference(event)}
            >
              {' '}
              {deliveryLabelTranslation.delivery_no_preference}
            </div>
          </Col>
        </Col>
      </div>
    </React.Fragment>
  );
};

export default Delivery;
